import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AdSlot from './AdSlot';
import useFreewheel from '../../infrastructure/freewheel/useFreewheel';
import { useAppSelector } from '../../hooks/useReduxHelper';
import { selectUserName } from '../../slices/User/UserSlice';

interface FixedAdProps {
  shouldDisplay: boolean;
  hideAd?: boolean;
}

const FixedAd: React.FC<FixedAdProps> = ({ shouldDisplay, hideAd }) => {
  const freewheelRequest = useFreewheel();
  const location = useLocation();

  const username = useAppSelector(selectUserName);

  useEffect(() => {
    if (shouldDisplay && !hideAd) {
      freewheelRequest(location.pathname);
    }
    // eslint-disable-next-line
  }, [location, freewheelRequest, shouldDisplay]);

  if (!shouldDisplay || hideAd) {
    return null;
  }

  return (
    <AdSlot
      width={300}
      height={600}
      slotId="fixed_ad"
      classNames={`banner-right banner-right-${username ? 'with-nav' : 'no-nav'}`}
    />
  );
};

export default FixedAd;
