import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../infrastructure/redux/store';
import { IUserDto } from '../../types/User';

export interface UserState {
  id: string;
  name: string;
  grandPrizeEntryCount: number | null;
  weeklyEntryCount: number | null;
  weekNumber: number | null;
  showNavBar: boolean;
  heartAddedToday: boolean;
  likedItemIds: string[];
  watchPremiereIds: string[];
  winPremiereCorrectIds: string[];
  winPremiereIncorrectIds: string[];
}

const initialState: UserState = {
  id: '',
  name: '',
  grandPrizeEntryCount: null,
  weeklyEntryCount: null,
  weekNumber: null,
  showNavBar: true,
  heartAddedToday: false,
  likedItemIds: [],
  watchPremiereIds: [],
  winPremiereCorrectIds: [],
  winPremiereIncorrectIds: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
    signup: (state, action: PayloadAction<IUserDto>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.weekNumber = action.payload.week;
      state.weeklyEntryCount = action.payload.weeklyEntryCount;
      state.grandPrizeEntryCount = action.payload.grandPrizeEntryCount;
      state.heartAddedToday = true;
      state.likedItemIds = action.payload.likedItemIds;
      state.watchPremiereIds = action.payload.watchPremiereIds;
      state.heartAddedToday = action.payload.heartAddedToday;
      state.winPremiereCorrectIds = action.payload.winPremiereCorrectIds;
      state.winPremiereIncorrectIds = action.payload.winPremiereIncorrectIds;
    },
    updateUser: (state, action: PayloadAction<IUserDto>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.weekNumber = action.payload.week;
      state.weeklyEntryCount = action.payload.weeklyEntryCount;
      state.grandPrizeEntryCount = action.payload.grandPrizeEntryCount;
      state.likedItemIds = action.payload.likedItemIds;
      state.watchPremiereIds = action.payload.watchPremiereIds;
      state.heartAddedToday = action.payload.heartAddedToday;
      state.winPremiereCorrectIds = action.payload.winPremiereCorrectIds;
      state.winPremiereIncorrectIds = action.payload.winPremiereIncorrectIds;
    }, // TODO these can likely just become one function 'update'
  },
});

export const { signup, updateUser, logout } = userSlice.actions;

export const selectIsSignedIn = (state: RootState) => Boolean(state.user.id);
export const selectHeartAddedToday = (state: RootState) => state.user.heartAddedToday;
export const selectUserName = (state: RootState) => state.user.name;
export const selectUserId = (state: RootState) => state.user.id;
export const selectIsLiked = (state: RootState) => (id: string) => state.user.likedItemIds.includes(id);
export const selectWatchedPremiereIds = (state: RootState) => state.user.watchPremiereIds;
export const selectWinState =
  (state: RootState) =>
  (winPremiereId: string): 'Correct' | 'Incorrect' | 'Unanswered' => {
    if (state.user.winPremiereCorrectIds && state.user.winPremiereCorrectIds.includes(winPremiereId)) {
      return 'Correct';
    }
    if (state.user.winPremiereIncorrectIds && state.user.winPremiereIncorrectIds.includes(winPremiereId)) {
      return 'Incorrect';
    }
    return 'Unanswered';
  };

export default userSlice.reducer;
