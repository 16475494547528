import stateLocations from '../../../constants/stateLocations';
import { ReactComponent as Icon } from 'images/icons/icon-map-marker-heart.svg';
import { MapPinProps } from 'types';

const MapPin: React.FC<MapPinProps> = ({ state }) => {
  return (
    <div
      style={{
        left: `${stateLocations[state][0]}%`,
        top: `${stateLocations[state][1]}%`,
        zIndex: `${stateLocations[state][1]}`,
      }}
      className="map-item"
    >
      {/* <img src={imageUrl} alt="" className="map-image" /> */}
      <Icon className="map-marker" />
    </div>
  );
};

export default MapPin;
