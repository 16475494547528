import { Route, Routes, useLocation } from 'react-router-dom';
import { useScrollToTop } from '../../hooks';
import LandingPage from '../../components/Pages/LandingPage';
import NotFoundPage from './NotFound404';
import * as Paths from 'constants/Routes';
import CloseWindow from './CloseWindow';
import OptionalAnimatePresence from 'components/Shared/OptionalAnimatePresence';
import RulesPage from 'components/Pages/RulesPage';
import SignUpPage from 'components/Pages/SignUpPage';
import GalleryPage from 'components/Pages/GalleryPage';
import SuccessPage from '../../components/Pages/SuccessPage';
import RewardsPage from '../../components/Pages/RewardsPage';
import SignedInUploadPage from '../../components/Pages/SignedInUploadPage';
import PrivateRoute from './PrivateRoute';

const AppRoutes = () => {
  const location = useLocation();
  useScrollToTop(location.pathname);
  return (
    // @ts-ignore
    <OptionalAnimatePresence>
      <Routes location={location}>
        {/* Navigation Routes */}
        <Route path={Paths.LandingRoute} element={<LandingPage />} key="landing" />
        <Route path={Paths.SignUpFormRoute} element={<SignUpPage />} key="signup" />
        <Route
          path={Paths.SuccessRoute}
          element={
            <PrivateRoute>
              <SuccessPage />
            </PrivateRoute>
          }
          key="success"
        />
        <Route path={Paths.GalleryRoute} element={<GalleryPage />} key="gallery" />
        {/* <Route path={Paths.RewardsRoute} element={<RewardsPage />} key="rewards" /> */}
        <Route
          path={Paths.SignedInUploadRoute}
          element={
            <PrivateRoute>
              <SignedInUploadPage />
            </PrivateRoute>
          }
          key="upload"
        />
        <Route path={Paths.RulesRoute} element={<RulesPage />} key="rules" />
        <Route path="/closewindow" element={<CloseWindow />} key="closewindow" />

        {/* Error Routes */}
        <Route path="*" element={<NotFoundPage />} key="notfound" />
      </Routes>
    </OptionalAnimatePresence>
  );
};

export default AppRoutes;
