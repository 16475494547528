import EventTrackingData from 'constants/EventTracking';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import React, { useEffect, useRef, useState } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import { Button } from '../Layout';

interface IVideoPlayerProps {
  videoUrl: string;
  playcardUrl: string;
  onPlayCallback?: Function;
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ videoUrl, playcardUrl, onPlayCallback }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoPlayerRef = useRef<VideoJsPlayer | null>(null);
  const [showButton, setShowButton] = useState<boolean>(true);

  const videoJsOptions: VideoJsPlayerOptions = {
    autoplay: false,
    playbackRates: [1],
    width: 1920,
    height: 1080,
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
      volumePanel: false,
      playbackRateMenuButton: false,
      // fullscreenToggle: false,
    },
    sources: [
      {
        src: videoUrl,
        type: 'video/mp4',
      },
    ],
  };

  useEffect(() => {
    function disposePlayer() {
      if (videoPlayerRef.current && videoPlayerRef.current.dispose) {
        videoPlayerRef.current.dispose();
      }
    }

    if (videoRef.current) {
      videoPlayerRef.current = videojs(videoRef.current, videoJsOptions);
      videoPlayerRef.current.on('play', () => {
        setShowButton(false);
        if (onPlayCallback) {
          onPlayCallback();
        }
      });
      // videoPlayerRef.current.on('play', () => trackEvent('PrizeSuccess', 'VideoPlay', videoUrl));
    }

    return disposePlayer;
    // eslint-disable-next-line
  }, []);

  const onButtonClick = async () => {
    trackEventData(EventTrackingData.WatchPreviewButtonClick);
    if (videoPlayerRef.current) {
      await videoPlayerRef.current.play();
    }
  };

  return (
    <>
      <div data-vjs-player>
        <div className="video-preview">
          <video
            ref={(node) => (videoRef.current = node)}
            className="video-js vjs-default-skin vjs-big-play-centered"
            controls
            playsInline={true}
            preload="auto"
            poster={playcardUrl}
            data-setup='{"fluid": true}'
          />
        </div>
      </div>
      {showButton && (
        <Button className="btn-preview" onClick={onButtonClick}>
          Watch Preview
        </Button>
      )}
    </>
  );
};

export default VideoPlayer;
