import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useWindowDimensions } from '../../../hooks';
import { useAppSelector } from '../../../hooks/useReduxHelper';
import { selectUserName } from '../../../slices/User/UserSlice';
import NavBarUserData from './NavBarUserData';
import NavBarLogos from './NavBarLogos';
import NavBarLogoutLink from './NavBarLogoutLink';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';

const NavBar: React.FC = () => {
  const { windowWidth } = useWindowDimensions();
  const username = useAppSelector(selectUserName);
  const location = useLocation();

  const showMobileNavBar = windowWidth < 992;

  if (!showMobileNavBar) {
    return (
      <div className={`nav-bar-sp0 nav-bar-sp0-lg nav-bar-visible`}>
        {username && (
          <div className="nav-bar">
            <NavBarUserData />
            <NavBarLogoutLink username={username} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`nav-bar-sp0 nav-bar-sp0-sm ${username ? 'nav-bar-visible' : ''}`}>
      <div className="nav-bar">
        <div className="mobile-nav-item">
        <NavBarUserData />
          {username && <NavBarLogoutLink username={username} />}

        </div>
      </div>
    </div>
  );
};

export default NavBar;
