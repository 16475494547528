export const UrlNameKey = 'urlPath';
export const UrlNameKeyWithColon = `:${UrlNameKey}`;

export const LandingRoute = '/';
export const SignUpFormRoute = '/signup';
export const SignInFormRoute = '/signin';
export const GalleryRoute = '/gallery';
export const SuccessRoute = '/success';
export const RewardsRoute = '/rewards';
export const SignedInUploadRoute = '/upload';

export const RulesRoute = '/rules';
