import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import uploadToAzure, { uploadDataUrlToAzure } from 'helpers/UploadToAzure';
import { iconUpload, iconWarning } from '../../images/icons';
import { useEffect, useState } from 'react';
import { megabytesToBytes } from 'helpers/index';
import classNames from 'classnames';
import EventTrackingData from '../../constants/EventTracking';
import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import createAndUploadThumbnail from '../../helpers/CreateThumbnail';
import { Button } from 'components/Layout';
import { FormFeedback } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { SignedInUploadRoute, SignUpFormRoute } from 'constants/Routes';
import { isMobile } from 'react-device-detect';

const maxFileSizeInMegabytes = 100;

interface IDropzoneFieldProps {
  name: string;
  isUploading: boolean;
  setIsUploading: (uploading: boolean) => void;
}

const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'file-invalid-type':
      return 'Sorry! This is an invalid file type. Please select a photo.';
    case 'file-too-large':
      return `Sorry! Files must be less than ${maxFileSizeInMegabytes}MB.`;
    default:
      return 'Sorry! There was a problem with your upload. Please try again.';
  }
};

const DropzoneMessage: React.FC<{ errorMessage: string | null; isDragActive: boolean }> = ({
  errorMessage,
  isDragActive,
}) => {
  if (errorMessage) {
    return <p className="error">{errorMessage}</p>;
  }
  const message = isDragActive ? 'Drop the file here ...' : 'You can drag and drop a photo or use the button below';
  return <p>{!isMobile && message}</p>;
};

const DropzoneField: React.FC<IDropzoneFieldProps> = ({ name, isUploading, setIsUploading }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();
  const [field, meta, { setValue }] = useField(name);
  const [thumbnailField, thumbnailMeta, { setValue: setThumbnailValue }] = useField('thumbnailUrl');
  const [filename, setFilename] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [page, setPage] = useState<string | null>(null);

  const onDropAccepted = (files: File[]) => {
    // const event = files[0].type.includes('image') ? EventTrackingData.UploadPhoto : EventTrackingData.UploadVideo;
    if (page === 'signup') {
      trackEventData(EventTrackingData.MediaFormUploadBeginSignUpPage);
    }
    if (page === 'upload') {
      trackEventData(EventTrackingData.MediaFormUploadBeginUploadPage);
    }

    setErrorMessage(null);
    setIsUploading(true);

    const file = files[0];
    const thumbPromise = createAndUploadThumbnail(file, setThumbnailValue);
    const originalPromise = uploadToAzure(file, `${name.toLowerCase()}.${file.name.split('.').pop()}`);

    Promise.all([originalPromise, thumbPromise])
      .then((promiseResponse) => {
        const [oringalResponse, thumbResponse] = promiseResponse;
        setValue(oringalResponse.url);
        setFilename(file.name);
        setThumbnailValue(thumbResponse);
        setIsUploading(false);
        if (page === 'signup') {
          trackEventData(EventTrackingData.MediaFormUploadSuccessSignUpPage);
        }
        if (page === 'upload') {
          trackEventData(EventTrackingData.MediaFormUploadSuccessUploadPage);
        }
      })
      .catch(() => {
        setErrorMessage('Sorry! There was a problem with your upload. Please try again.');
        if (page === 'signup') {
          trackEventData(EventTrackingData.MediaFormUploadErrorSignUpPage);
        }
        if (page === 'upload') {
          trackEventData(EventTrackingData.MediaFormUploadErrorUploadPage);
        }
      });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected: (fileErrors) => {
      if (fileErrors && fileErrors.length && fileErrors[0].errors && fileErrors[0].errors.length) {
        const error = fileErrors[0].errors[0];
        const errorMessage = getErrorMessage(error.code);
        setErrorMessage(errorMessage);
      }
    },
    multiple: false,
    accept: 'image/*',
    maxFiles: 1,
    maxSize: megabytesToBytes(maxFileSizeInMegabytes),
  });

  const removeDownloadClick = () => {
    setValue('');
    setFilename(null);
    setThumbnailValue(null);
    if (page === 'signup') {
      trackEventData(EventTrackingData.MediaFormUploadRemoveSignUpPage);
    }
    if (page === 'upload') {
      trackEventData(EventTrackingData.MediaFormUploadRemoveUploadPage);
    }
  };

  useEffect(() => {
    if (location.pathname === SignUpFormRoute) {
      setPage('signup');
    }
    if (location.pathname === SignedInUploadRoute) {
      setPage('upload');
    }
  }, [page, location]);

  if (filename) {
    return (
      <div className="upload-item text-center uploading uploaded">
        <div className="dropzone-area"></div>
        <p> File uploaded!</p>
        <p>Now hit Confirm below to complete your submission.</p>
        <span className="remove-image">
          <span className="material-icons-outlined mx-1">image</span> {filename}{' '}
          <Button variant="delete" onClick={removeDownloadClick}>
            Remove
          </Button>
        </span>
      </div>
    );
  }

  if (isUploading) {
    return (
      <div className="upload-item text-center uploading">
        <div className="dropzone-area"></div>
        <p className="uploading">Uploading</p>
        <p>This can take a few minutes!</p>
      </div>
    );
  }

  return (
    <>
      <div className={classNames('upload-item text-center', { error: errorMessage !== null })} {...getRootProps()}>
        <div className="dropzone-area"></div>
        <input className="dropzone-zone" {...getInputProps()} />
        <img src={errorMessage ? `${iconWarning}` : `${iconUpload}`} alt="" className="img-fluid icon-upload" />
        <DropzoneMessage isDragActive={isDragActive} errorMessage={errorMessage} />
        <span className="btn btn-outline-primary mb-5">select a photo</span>
      </div>
      {meta.touched && (
        <FormFeedback tooltip valid={!meta.error}>
          {meta.error}
        </FormFeedback>
      )}
    </>
  );
};
export default DropzoneField;
