export interface config {
  apiShareUrl: string;
  apiUrl: string;
  appUrl: string;
  crossDomainGoogleAnalyticsWebPropertyId: string;
  enableComScore: boolean;
  enableFreewheel: boolean;
  enableGoogleAnalytics: boolean;
  enableOmniture: boolean;
  facebookAppId: string;
  googleAnalyticsWebPropertyId: string;
  navBarColor: string;
  navBarFranchise: string;
  navBarLink: string;
  networkId: number;
  profileId: string;
  serverUrl: string;
  siteSectionId: string;
  tweetGeneric: string;
  tweetMyTree: string;
  pinterestGeneric: string;
  pinterestMyTree: string;
  tweet: string;
  pinterestDescription: string;
  consoleLogEvents: boolean;
  facebookQuote: string;
  competitionClosed: boolean;
  showHeartCount: boolean;
}

declare global {
  interface Window {
    config: config;
    gtag: any;
    COMSCORE: any;
    s: any;
    formEntryTimeout?: ReturnType<typeof setTimeout>;
    sizeHandler: () => void;
    isIOS: () => boolean;
  }
}

const GetConfig = () => {
  return window.config;
};

export default GetConfig;
