import * as Paths from './Routes';

export interface PageData {
  title: string;
  path: string;
}

type PageDataMap = {
  [pageDataKey in
    | 'LandingPageData'
    | 'SignUpFormPageData'
    | 'SignUpSuccessPageData'
    | 'RewardsPageData'
    | 'GalleryPageData'
    | 'UploadPageData'
    | 'RulesPageData'
    | 'NotSignedInModalData'
    | 'ViewPrizesModalData']: PageData;
};

const PageTrackingData: PageDataMap = {
  LandingPageData: {
    title: 'Landing',
    path: Paths.LandingRoute,
  },
  SignUpFormPageData: {
    title: 'Sign Up',
    path: Paths.SignUpFormRoute,
  },
  SignUpSuccessPageData: {
    title: 'Success',
    path: Paths.SuccessRoute,
  },
  RewardsPageData: {
    title: 'Rewards',
    path: Paths.RewardsRoute,
  },
  GalleryPageData: {
    title: 'Gallery',
    path: Paths.GalleryRoute,
  },
  UploadPageData: {
    title: 'Upload',
    path: Paths.SignedInUploadRoute,
  },
  RulesPageData: {
    title: 'Rules',
    path: Paths.RulesRoute,
  },
  NotSignedInModalData: {
    title: 'Not Signed In Modal',
    path: '/gallery/not-signed-in-modal',
  },
  ViewPrizesModalData: {
    title: 'View Prizes Modal',
    path: '/landing/view-prizes-modal',
  },
};

export const GetRouteNameFromPath = (path: string) =>
  Object.values(PageTrackingData).find((x) => x.path === path)?.title;

export default PageTrackingData;
