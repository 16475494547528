import EventTrackingData from 'constants/EventTracking';
import PageTrackingData from 'constants/PageViewTracking';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import { Modal, Button } from 'react-bootstrap';
import { IPrizeItemDto } from 'types/Prizes';
import { trackPageViewGoogleWithPageData } from '../../infrastructure/tracking';

interface PrizeModalProps {
  onHide: () => void;
  show: boolean;
  prizes: IPrizeItemDto[];
  prizeWeek: number;
  mainPrizeImage: string;
}

const PrizeModal: React.FC<PrizeModalProps> = ({ onHide, show, prizes, prizeWeek, mainPrizeImage }) => {
  if (show) {
    trackPageViewGoogleWithPageData(PageTrackingData.ViewPrizesModalData);
  }

  const hasNoImages = prizes.every((x) => !x.imageUrl);

  return (
    <Modal show={show} centered onHide={onHide} className="reentry">
      <Modal.Body>
        <div className={`${hasNoImages ? 'prize-content no-image' : 'prize-content'}`}>
          <h1>Week {prizeWeek} Prizes</h1>
          {!hasNoImages && (
            <div className="prize-container">
              {prizes.map((prize, i) => {
                return (
                  <div className="prize-card" key={`${prizeWeek}-${i}`}>
                    <div className="card">
                      <img src={prize.imageUrl} className="card-img-top img-fluid" alt="" />
                      <div className="card-body">
                        <p className="card-text">{prize.title}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {hasNoImages && (
            <div className="prize-container no-images">
              <div className="prize-card">
                <div className="card">
                  <img src={mainPrizeImage} className="card-img-top img-fluid" alt="" />
                  <div className="card-body list-items">
                    {prizes.map((prize, i) => (
                      <p className="card-text" key={`prize${i}`}>
                        {prize.title}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Button
          variant="link"
          className="btn-modal mt-4"
          onClick={() => {
            onHide();
            trackEventData(EventTrackingData.ViewPrizesClose);
          }}
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default PrizeModal;
