import { AnonymousCredential, BlockBlobClient } from '@azure/storage-blob';
import { Buffer } from 'buffer';
import GetPresignedUrl from './GetPresignedUrl';

const uploadToAzure = async (file: File, filename?: string) => {
  const presignedUrlCredentials = await GetPresignedUrl(filename || file.name);
  const presignedUrl = presignedUrlCredentials.presignedUrl;

  const credentials = new AnonymousCredential();

  const blockBlobClient = new BlockBlobClient(presignedUrl, credentials);
  await blockBlobClient.uploadData(file);

  return {
    url: presignedUrlCredentials.assetUrl,
    filename: presignedUrlCredentials.fileName,
  };
};

export const uploadDataUrlToAzure = async (imageDataUrl: string, filename: string) => {
  const presignedUrlCredentials = await GetPresignedUrl(filename);
  const presignedUrl = presignedUrlCredentials.presignedUrl;

  const credentials = new AnonymousCredential();

  const blockBlobClient = new BlockBlobClient(presignedUrl, credentials);

  const base64 = imageDataUrl
    .replace('data:image/jpeg;base64,', '')
    .replace('data:image/png;base64,', '')
    .replace(' ', '');
  const buffer = Buffer.from(base64, 'base64');
  await blockBlobClient.uploadData(buffer);

  return {
    url: presignedUrlCredentials.assetUrl,
    filename: presignedUrlCredentials.fileName,
  };
};
export default uploadToAzure;
