import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'infrastructure/redux/store';
import { IFeedItemDto } from '../../types/Map';

export interface IMapFeedState {
  index: number;
  items: IFeedItemDto[];
}

const initialState: IMapFeedState = {
  index: -1,
  items: [],
};

export const mapFeedSlice = createSlice({
  name: 'mapFeed',
  initialState,
  reducers: {
    addMapFeedItems: (state, action: PayloadAction<IFeedItemDto[]>) => {
      state.items = action.payload;
      state.index = 0;
    },
    incrementImageIndex: (state) => {
      state.index = state.index + 1;
    },
  },
});

export const { addMapFeedItems, incrementImageIndex } = mapFeedSlice.actions;

export const selectIndex = (state: RootState) => state.mapFeed.index;
export const selectFeedItem = ({ mapFeed }: RootState) => (mapFeed.index < 0 ? null : mapFeed.items[mapFeed.index]);
export const selectNextFeedItem = ({ mapFeed }: RootState) =>
  mapFeed.index < 0 || mapFeed.index === mapFeed.items.length ? null : mapFeed.items[mapFeed.index + 1];
export const selectRequiresApiCall = ({ mapFeed }: RootState) =>
  mapFeed.index < 0 || mapFeed.index === mapFeed.items.length - 1;

export default mapFeedSlice.reducer;
