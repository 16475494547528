import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { iconHearts } from '../../images/icons';
import LoveuaryLogoCol from 'components/Hallmark/LoveuaryLogoCol';
import { IWatchPremiereDto } from 'types/WatchAndWin';
import CallApi from 'infrastructure/api/CallApi';
import { promotedGalleryUrl, watchPremiereUrl, winPremiereUrl } from 'constants/ApiUrls';
import VideoPlayer from 'components/Shared/VideoPlayer';
import { IUserDto } from '../../types/User';
import { enterWatchPremiereUrl } from '../../constants/ApiUrls';
import { IWatchPremiereEntryModel, IWinPremiereDto } from '../../types/WatchAndWin';
import { useAppSelector, useAppDispatch } from '../../hooks/useReduxHelper';
import { selectUserId, selectWatchedPremiereIds, selectWinState } from '../../slices/User/UserSlice';
import { updateUser } from 'slices/User/UserSlice';
import WatchAndWinForm from 'components/WatchAndWin/WatchAndWinForm';
import { usePageView } from 'hooks';
import PageTrackingData from 'constants/PageViewTracking';
import { IGalleryItemDto } from 'types';
import PromotedGallery from '../Gallery/PromotedGallery';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';
import MotionDiv from 'components/Shared/MotionDiv';

const watchDescription = (watchedIds: Array<string>, id: string, title: string, premiereDate: string) => {
  return watchedIds && watchedIds.includes(id) ? (
    <>
      <span className="subheader">
        <div className="watched">
          You've earned 1 bonus entry! Don't miss {title}, premiering {premiereDate} on Hallmark.
        </div>
      </span>
    </>
  ) : (
    <>
      <span className="subheader">Check out our all-new Loveuary premiere, {title}, and earn a bonus entry!</span>
    </>
  );
};

const SuccessPage: React.FC = (props) => {
  usePageView(PageTrackingData.SignUpSuccessPageData);
  const [watchPremiere, setWatchPremiere] = useState<IWatchPremiereDto | null>(null);
  const [winPremiere, setWinPremiere] = useState<IWinPremiereDto | null>(null);
  const [showWinPremierePlaceholder, setShowWinPremierePlaceholder] = useState<boolean>(false);
  const [promotedItems, setPromotedItems] = useState<Array<IGalleryItemDto> | null>(null);
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const watchedPremiereIds = useAppSelector(selectWatchedPremiereIds);
  const getAnswerState = useAppSelector(selectWinState);

  useEffect(() => {
    CallApi<IWatchPremiereDto>(watchPremiereUrl).then(({ data }) => {
      setWatchPremiere(data);
    });
    CallApi<IWinPremiereDto>(winPremiereUrl).then(({ data }) => {
      setWinPremiere(data);
      if (!data) {
        setShowWinPremierePlaceholder(true);
      }
    });
    CallApi<Array<IGalleryItemDto>>(promotedGalleryUrl).then(({ data }) => {
      setPromotedItems(data);
    });
  }, []);

  return (
    <>
      <Container className="success">
        <Row>
          <LoveuaryLogoCol />
          {/*  <Col xs={12}>
            <div className="card-download">
              <div className="info-header">
                <h1>Thank you so much!</h1>
                <img src={iconHearts} alt="icon-hearts" className="img-fluid" />
              </div>
              <p>We hope you enjoy this digital fan kit!</p>
              <Link to={RewardsRoute}>
                <Button
                  variant="outline-primary btn-download"
                  iconBefore
                  onClick={() => trackEventData(EventTrackingData.DownloadNowButtonClick)}
                >
                  Download Now
                </Button>
              </Link>
            </div>
          </Col>*/}
        </Row>
        <MotionDiv initial={{ y: '10%', opacity: 0 }} animate={{ y: '0', opacity: 1 }} transition={{ duration: 0.5 }}>
          <Row>
            <Col xs={12}>
              <div className="card-earn">
                <Row>
                  <Col xs={12}>
                    <div className="info-header">
                      <h1>Thank you so much!</h1>
                      <img src={iconHearts} alt="icon-hearts" className="img-fluid" />
                    </div>
                    <span className="success-subheader">Earn more entries!</span>
                    {watchPremiere && (
                      <>
                    <div className="section-number">
                      <span className="value">1</span>
                    </div>
                        {watchDescription(
                          watchedPremiereIds,
                          watchPremiere.id,
                          watchPremiere.title,
                          watchPremiere.premiereDate
                        )}
                        <VideoPlayer
                          playcardUrl={watchPremiere.thumbnailImageUrl}
                          videoUrl={watchPremiere.videoUrl}
                          onPlayCallback={() => {
                            const model: IWatchPremiereEntryModel = {
                              userId,
                              entityId: watchPremiere.id,
                            };
                            CallApi<IUserDto>(enterWatchPremiereUrl, 'POST', model)
                              .then(({ data }) => {
                                dispatch(updateUser(data));
                              })
                              .catch(({ response }) => {
                                if (
                                  response &&
                                  response.data &&
                                  response.data.error &&
                                  response.data.error.code === 'CustomError'
                                ) {
                                  console.log(response.data.error.message);
                                }
                              });
                            trackEventData(EventTrackingData.VideoPlay);
                          }}
                        />
                      </>
                    )}
                  </Col>
                  <WatchAndWinForm
                    isFirst={!watchPremiere}
                    premiere={winPremiere}
                    getAnswerState={getAnswerState}
                    userId={userId}
                    showWinPremierePlaceholder={showWinPremierePlaceholder}
                  />
                  <Col xs={12}>
                    <div className="section-number">
                      <span className="value">{!watchPremiere ? "2" : "3"}</span>
                    </div>
                    <PromotedGallery items={promotedItems} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </MotionDiv>
      </Container>
    </>
  );
};

export default SuccessPage;
