import { combineReducers, configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import webViewReducer from 'slices/WebView/WebViewSlice';
import userReducer from 'slices/User/UserSlice';
import galleryReducer from 'slices/Gallery/GallerySlice';
import mapFeedReducer from 'slices/MapFeed/MapFeedSlice';
import prizeReducer from 'slices/Prizes/PrizeSlice';
import { loadState, saveState } from './savedState';

const preloadedState = loadState();

const rootReducer = combineReducers({
  webView: webViewReducer,
  user: userReducer,
  gallery: galleryReducer,
  mapFeed: mapFeedReducer,
  prize: prizeReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: preloadedState,
});

store.subscribe(() =>
  saveState({
    user: { ...store.getState().user },
  })
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
