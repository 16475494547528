const ProviderList = [
  'AT&T UVERSE',
  'DIRECTV',
  'DISH',
  'XFINITY',
  'SPECTRUM',
  'COX',
  'OPTIMUM',
  'SUDDENLINK',
  'MEDIACOM',
  'FRONTIER',
  'VERIZON',
  'FRNDLY TV',
  'FUBO TV',
  'PHILO',
  'SLING TV',
  'YOUTUBE TV',
  'OTHER',
];

export default ProviderList;
