import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Button } from '../Layout';
import UnitCountdown from 'components/Shared/UnitCountdown';
import CallApi from 'infrastructure/api/CallApi';
import { totalApprovedHeartsUrl, countdownSecondsRemainingUrl } from 'constants/ApiUrls';
import { GalleryRoute } from 'constants/Routes';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';
import MotionDiv from 'components/Shared/MotionDiv';
import GetConfig from 'helpers/GetConfig';
import { numberWithCommas } from '../../helpers/index';

const { showHeartCount } = GetConfig();

const LandingGallery: React.FC = () => {
  const [totalHearts, setTotalHearts] = useState<string | null>(null);
  const [countdownSecondsRemaining, setCountdownSecondsRemaining] = useState<number | null>(null);

  useEffect(() => {
    CallApi<number>(totalApprovedHeartsUrl).then(({ data }) => setTotalHearts(numberWithCommas(data)));
    CallApi<number>(countdownSecondsRemainingUrl).then(({ data }) => setCountdownSecondsRemaining(data));
  }, []);

  return (
    <Row>
      <Col sm={12}>
        <div className="landing-gallery-card">
          {showHeartCount && (
            <div className="heart-total-container">
              <>
                <span className="heart-total-heading">Total Hearts</span>
                <span className="heart-total">{totalHearts}</span>
              </>
            </div>
          )}
          <Link to={GalleryRoute}>
            <Button
              variant="outline-primary"
              withArrow
              onClick={() => {
                trackEventData(EventTrackingData.ViewGalleryClickLandingPage);
              }}
            >
              View Gallery
            </Button>
          </Link>
        </div>

        {countdownSecondsRemaining && (
          <Row>
            <Col lg={{ span: 6, offset: 3 }} className="card-countdown">
              <MotionDiv
                initial={{ y: '10%', opacity: 0 }}
                animate={{ y: '0', opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="countdown">
                  <p>SWEEPSTAKES ENDS IN</p>
                  <UnitCountdown target={Date.now() + countdownSecondsRemaining * 1000} />
                </div>
              </MotionDiv>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default LandingGallery;
