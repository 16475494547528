import React, { Suspense } from 'react';
import { isIE } from 'react-device-detect';

const AnimatePresence = React.lazy(() =>
  import('framer-motion').then((module) => ({ default: module.AnimatePresence }))
);

interface OptionalAnimatePresenceProps {
  children: JSX.Element;
}

const OptionalAnimatePresence = ({ children }: OptionalAnimatePresenceProps) => {
  if (isIE) {
    return children;
  }
  return (
    <Suspense fallback={<div></div>}>
      <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
    </Suspense>
  );
};

export default OptionalAnimatePresence;