import React from 'react';
import SocialIcons from './SocialIcons';
import HallmarkChannelLogo from './HallmarkChannelLogo.svg';
import FooterAd from '../Ads/FooterAd';
import useLinkTarget from 'infrastructure/isWebView/useLinkTarget';

const renderIcons = {
  facebook: SocialIcons.Facebook,
  twitter: SocialIcons.Twitter,
  instagram: SocialIcons.Instagram,
  pinterest: SocialIcons.Pinterest,
  youtube: SocialIcons.Youtube,
};

function SocialIcon({ link, name, target }) {
  const Icon = renderIcons[name];

  return (
    <a
      className="SocialLink media-socialLinks-item-link"
      rel="noreferrer"
      href={link}
      target={target}
      data-social-service={name}
    >
      <Icon />
      <span className="sr-only">{name}</span>
    </a>
  );
}

const HallmarkFooter = ({ shouldDisplay }) => {
  const target = useLinkTarget();
  return (
    <>
      <footer className="Page-footer">
        <div className="Page-footer-Ad">
          <FooterAd shouldDisplay={shouldDisplay} classNames="banner-footer" />
        </div>
        <div className="Page-footer-container">
          <div className="Page-footer-containerTop">
            <div className="Page-footer-logo">
              <a aria-label="home page" href="https://www.hallmarkchannel.com/" target={target}>
                <img className="PageLogo-image" src={HallmarkChannelLogo} alt="Hallmark Channel Logo" />
              </a>
            </div>

            <div className="Page-footer-social">
              <div className="SocialBar">
                <div className="SocialBar-heading">Follow Us</div>

                <ul className="SocialBar-items">
                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://twitter.com/hallmarkchannel" name="twitter" target={target} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.instagram.com/hallmarkchannel/" name="instagram" target={target} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.youtube.com/hallmarkchannelusa" name="youtube" target={target} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.pinterest.com/hallmarkchannel" name="pinterest" target={target} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.facebook.com/hallmarkchannel" name="facebook" target={target} />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="Page-footer-containerBottom">
            <div className="Page-footer-navigation">
              <nav className="FooterNavigation" aria-label="footer-navigation">
                <ul className="FooterNavigation-items">
                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkmoviesandmysteries.com/"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Hallmark Movies &amp; Mysteries
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkdrama.com/"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Hallmark Drama
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hmnow.com"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Hallmark Movies Now
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/about-us"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          About Us
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/contact-us"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/faq"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          FAQ
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="http://corporate.crownmedia.com/#/careers"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Careers
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/advertising"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Advertising
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.crownmediainternational.com/"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          International
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="http://corporate.crownmedia.com/"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Corporate
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="http://www.hallmarkchannelpress.com/?SiteID=142&amp;NodeID=144"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Press
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/channel-locator"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Channel Locator
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/newsletter"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Newsletter
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/privacy-policy"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/crown-media-family-networks-terms-of-use"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Terms of Use
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/ca-privacy-policy"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          CA Privacy Notice
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/do-not-sell-my-info"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          CA Do Not Sell My Info
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="http://www.hallmark.com/"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Hallmark Cards
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="FooterNavigation-items-item navigation-footer-item">
                    <div className="FooterNavigationItem">
                      <div className="FooterNavigationItem-text">
                        <a
                          className="FooterNavigationItem-text-link"
                          href="https://www.hallmarkchannel.com/accessibility-notice"
                          target={target}
                          rel="noopener noreferrer"
                        >
                          Accessibility
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="Page-footer-disclaimer">
              <div className="cms-textAlign-center">
                Copyright © 2022 Crown Media Family Networks, all rights reserved
              </div>
            </div>

            <div className="Page-footer-social">
              <div className="SocialBar">
                <div className="SocialBar-heading">Follow Us</div>

                <ul className="SocialBar-items">
                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://twitter.com/hallmarkchannel" name="twitter" target={target} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.instagram.com/hallmarkchannel/" name="instagram" target={target} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.youtube.com/hallmarkchannelusa" name="youtube" target={target} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.pinterest.com/hallmarkchannel" name="pinterest" target={target} />
                  </li>

                  <li className="SocialBar-items-item">
                    <SocialIcon link="https://www.facebook.com/hallmarkchannel" name="facebook" target={target} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HallmarkFooter;
