import Countdown, { CountdownRendererFn, zeroPad } from 'react-countdown';

const TimeUnit: React.FC<{ shouldRender: boolean; value: number; unitName: string; isLast?: boolean }> = ({
  shouldRender,
  value,
  isLast = false,
}) => {  
  return (
    <>
      <span>{zeroPad(value)} </span>
    </>
  );
};

const render: CountdownRendererFn = ({ days, hours, minutes, completed }) =>
  completed ? (
    <span className="unlock-state">Sweepstakes Closed</span>
  ) : (
    <div className="countdown-group">
      <div className="countdown-number">
        <TimeUnit shouldRender={days > 0} value={days} unitName="Days" />
        <span className="descriptor">days</span>
      </div>
      <div className="time-seperator">
        <span></span>
        <span></span>
      </div>
      <div className="countdown-number">
        <TimeUnit shouldRender={days + hours > 0} value={hours} unitName="Hours" />
        <span className="descriptor">hours</span>
      </div>
      <div className="time-seperator">
        <span></span>
        <span></span>
      </div>
      <div className="countdown-number">
        <TimeUnit shouldRender={days + hours + minutes > 0} value={minutes} unitName="Minutes" />
        <span className="descriptor">minutes</span>
      </div>
    </div>
  );

interface IUnitCountdownProps {
  target: number;
}

const UnitCountdown: React.FC<IUnitCountdownProps> = ({ target }) =>
  target === 0 ? null : <Countdown date={target} renderer={render} />;

export default UnitCountdown;
