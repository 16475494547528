/* eslint-disable no-useless-escape */
const sessionStorageKey = 'MotherlandWitchTesting';

export const numberWithCommas: (input: number) => string = (input) =>
  input !== undefined ? input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';

export const insertItemAtIndex: <T>(array: Array<T>, item: T, index: number) => Array<T> = (array, item, index) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
];

export const getRandomItemFromArray: <T>(array: Array<T>) => T = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const onExceptionTestForClosed = (e: any) => {
  if (
    e.response &&
    e.response.data &&
    e.response.data.error &&
    e.response.data.error.code === 'CustomError.CompetitionClosed'
  ) {
    setTimeout(() => (window.location.href = '/'));
  }
};

export const formatTime: (seconds: number) => string = (seconds: number) => {
  // Minutes
  const minutes = Math.floor(seconds / 60);
  // Seconds
  const partInSecondsNumber = seconds % 60;
  // Adds left zeros to seconds
  const partInSeconds = partInSecondsNumber.toString().padStart(2, '0');
  // Returns formated time
  return `${minutes}:${partInSeconds}`;
};

export const saveToSessionStorage = (shareCode: string) => {
  try {
    sessionStorage.setItem(sessionStorageKey, shareCode);
  } catch (error) {}
};

export const getFromSessionStorage = () => {
  try {
    const shareCode = sessionStorage.getItem(sessionStorageKey);
    return shareCode;
  } catch (error) {
    return null;
  }
};

export const getUrlParams = (name: string, url: string) => {
  const adjustedName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + adjustedName + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results || !results[2]) return null;
  return results[2];
};

export const getEntryText = (entryCount: number) => (entryCount === 1 ? 'entry' : 'entries');

export const zeroPad = (value: number) => value.toString().padStart(2, '0');

export const getPageNumbers = (currentPage: number, lastPage: number) => {
  let startPage = currentPage < 5 ? 1 : currentPage - 4;
  const targetEndPage = startPage + 8;
  const endPage = targetEndPage > lastPage ? lastPage : targetEndPage;
  let length = endPage - startPage + 1;
  if (length < 9) {
    const targetStartPage = endPage - 8;
    startPage = targetStartPage < 1 ? 1 : targetStartPage;
    length = endPage - startPage + 1;
  }
  return Array.from({ length }, (_, i) => i + startPage);
};

export const getAllUrlParams: (url?: string | null) => object = (url = null) => {
  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {} as any;

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          // @ts-ignore
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};

export const megabytesToBytes = (mb: number) => 1024 * 1024 * mb;
