import React, { useEffect } from 'react';
import OptionalAnimatePresence from 'components/Shared/OptionalAnimatePresence';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as MapUSA } from 'images/landing/map.svg';
import MapPin from './MapPin';
import MapImage from './MapImage';
import stateLocations from 'constants/stateLocations';
import { useAppSelector } from 'hooks/useReduxHelper';
import MotionDiv from 'components/Shared/MotionDiv';
import {
  addMapFeedItems,
  incrementImageIndex,
  selectFeedItem,
  selectIndex,
  selectRequiresApiCall,
  selectNextFeedItem,
} from 'slices/MapFeed/MapFeedSlice';
import CallApi from 'infrastructure/api/CallApi';
import { IFeedListDto } from '../../../types/Map';
import { mapFeedUrl } from 'constants/ApiUrls';
import { useDispatch } from 'react-redux';
import { loadSingleImage } from '../../../hooks/useImages';

const feedInterval = 5000;
const MapContainer: React.FC = () => {
  const dispatch = useDispatch();
  const feedItem = useAppSelector(selectFeedItem);
  const nextFeedItem = useAppSelector(selectNextFeedItem);
  const feedIndex = useAppSelector(selectIndex);
  const apiCallRequired = useAppSelector(selectRequiresApiCall);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (apiCallRequired) {
      CallApi<IFeedListDto>(mapFeedUrl).then(({ data }) => {
        if (data.feed.length) {
          const loadFirstImagePromise = loadSingleImage(data.feed[0].url);
          loadFirstImagePromise.finally(() => {
            dispatch(addMapFeedItems(data.feed));
          });
        }
      });
    } else {
      timeout = setTimeout(() => dispatch(incrementImageIndex()), feedInterval);
    }

    if (nextFeedItem) {
      loadSingleImage(nextFeedItem.url);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [feedIndex]);

  return (
    <Row>
      <Col className="map-container">
        <MotionDiv
          initial={{ y: '10%', opacity: 0 }}
          animate={{ y: '0', opacity: 1 }}
          exit={{ y: '0', opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <MapUSA className="map" />
          {feedItem && (
            <OptionalAnimatePresence>
              <div className="map-pins-container">
                <MapImage
                  key={feedItem.url}
                  // @ts-ignore
                  state={feedItem.state}
                  imageUrl={feedItem.url}
                />
              </div>
            </OptionalAnimatePresence>
          )}
          <div className="map-pins-container">
            {Object.keys(stateLocations).map((state) => (
              //@ts-ignore
              <MapPin state={state} key={`${state}pin`} />
            ))}
          </div>
        </MotionDiv>
      </Col>
    </Row>
  );
};

export default MapContainer;
