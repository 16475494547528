import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Layout';

const NotFound404 = () => (
  <React.Fragment>
    <div className="page page-error">
      <h1>Oops, looks like we can't find the page you were looking for.</h1>
      <Link to="/">
        <Button variant="primary" size="lg">
          Back to homepage
        </Button>
      </Link>
    </div>
  </React.Fragment>
);

export default NotFound404;
