import React from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';
import { Link } from 'react-router-dom';

const Disclaimer: React.FC = () => {
  const location = useLocation();

  return (
    <Row>
      <Col xs={12}>
        <div className={`disclaimer disclaimer-${location.pathname.substring(1)}`}>
          <p>
            <span className="text-medium">
              NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCE OF WINNING.{' '}
            </span>
            Sweepstakes begins at 10:00:00am ET on 1/24/22 and ends at 11:59:59pm ET on 2/28/22. Promotion is open to
            legal residents of 50 United States or D.C who are 21 years of age or older. Void where prohibited by law.{' '}
            <Link to="/rules" onClick={() => trackEventData(EventTrackingData.DisclaimerClickHereClick)}>
              Click here
            </Link>{' '}
            for complete Official Rules including prize details. Sponsor: Crown Media United States, LLC.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default Disclaimer;
