import GetConfig from 'helpers/GetConfig';
import { iconTicket } from 'images/icons';

import { useAppSelector } from '../../../hooks/useReduxHelper';

const { competitionClosed } = GetConfig();

const NavBarUserData: React.FC = () => {
  const { weekNumber, weeklyEntryCount, grandPrizeEntryCount } = useAppSelector((state) => state.user);

  if (weeklyEntryCount === null || weekNumber === null || grandPrizeEntryCount === null) {
    return null;
  }

  return (
    <div className="user-data">
      {!competitionClosed && (
        <span className="weekly-prize">
          Week {weekNumber} Prize:
          <span className="pill"><img src={iconTicket} alt="" className="img-fluid"/> {weeklyEntryCount}</span>
        </span>
      )}
      <span className="grand-prize">
        <span className="descriptor">Grand Prize:&nbsp;</span>
        <span className="pill"><img src={iconTicket} alt="" className="img-fluid"/> {grandPrizeEntryCount}</span>
      </span>
    </div>
  );
};

export default NavBarUserData;
