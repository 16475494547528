import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LandingRoute } from '../../constants/Routes';
import { LoveuaryLogo } from 'images/logos';
import MotionDiv from 'components/Shared/MotionDiv';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';

const LoveuaryLogoCol: React.FC = () => (
  <Col xs={12} className="text-center">
    <MotionDiv
      initial={{ y: '10%', opacity: 0 }}
      animate={{ y: '0', opacity: 1 }}
      exit={{ y: '0', opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Link to={LandingRoute}>
        <img
          src={LoveuaryLogo}
          alt="loveuary-logo"
          className="main-logo img-fluid"
          onClick={() => trackEventData(EventTrackingData.LoveuaryLogoClick)}
        />
      </Link>
    </MotionDiv>
  </Col>
);

export default LoveuaryLogoCol;
