import Axios, { AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import GetConfig from '../../helpers/GetConfig';

const CallApi: <T>(url: string, method?: Method, data?: object, accessToken?: string) => AxiosPromise<T> = (
  url,
  method = 'GET',
  data,
  accessToken
) => {
  const { apiUrl } = GetConfig();

  const request: AxiosRequestConfig = {
    method,
    url: `${apiUrl}${url}`,
    headers: {
      Pragma: 'no-store',
    },
  };
  if (method && (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT')) {
    if (data) {
      request.data = data;
    }
    request.headers!['Content-Type'] = 'application/json';
  }
  if (accessToken) {
    request.headers!['Authorization'] = `Bearer ${accessToken}`;
  }

  return Axios(request);
};

export default CallApi;
