import { IGalleryItemDto } from 'types';

export const GalleryItemsPerPage = 12;

export const PlaceholderPromotedItems: Array<IGalleryItemDto> = [
  {
    state: 'Florida',
    username: 'Joe',
    likeCount: 0,
    originalUrl: 'https://1240369105.rsc.cdn77.org/loveuary/promoted/gallery-example.png',
    thumbnailUrl: 'https://1240369105.rsc.cdn77.org/loveuary/promoted/gallery-example.png',
    id: '',
  },
  {
    state: 'Alabama',
    username: 'Andy',
    likeCount: 0,
    originalUrl: 'https://1240369105.rsc.cdn77.org/loveuary/promoted/gallery-example2.png',
    thumbnailUrl: 'https://1240369105.rsc.cdn77.org/loveuary/promoted/gallery-example2.png',
    id: '',
  },
  {
    state: 'Texas',
    username: 'Steve',
    likeCount: 0,
    originalUrl: 'https://1240369105.rsc.cdn77.org/loveuary/promoted/gallery-example3.png',
    thumbnailUrl: 'https://1240369105.rsc.cdn77.org/loveuary/promoted/gallery-example3.png',
    id: '',
  },
];
