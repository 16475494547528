import React, { Suspense } from 'react';
import { isIE } from 'react-device-detect';

const FramerMotionDiv = React.lazy(() => import('framer-motion').then((module) => ({ default: module.motion.div })));

const MotionDiv = ({ children, ...props }: any) => {
  if (isIE) {
    return (
      <div {...props} className={props?.className}>
        {children}
      </div>
    );
  }
  return (
    <Suspense fallback={<div></div>}>
      <FramerMotionDiv {...props} className={props?.className}>
        {children}
      </FramerMotionDiv>
    </Suspense>
  );
};



const FramerMotionCol = React.lazy(() => import('framer-motion').then((module) => ({ default: module.motion.col })));

export const MotionCol = ({ children, ...props }: any) => {
  if (isIE) {
    return (
      <div {...props} className={props?.className}>
        {children}
      </div>
    );
  }
  return (
    <Suspense fallback={<div></div>}>
      <FramerMotionCol {...props} className={props?.className}>
        {children}
      </FramerMotionCol>
    </Suspense>
  );
};


const FramerMotionHeader = React.lazy(() => import('framer-motion').then((module) => ({ default: module.motion.h1 })));

export const MotionH1 = ({ children, ...props }: any) => {
  if (isIE) {
    return (
      <h1 {...props} className={props?.className}>
        {children}
      </h1>
    );
  }
  return (
    <Suspense fallback={<div></div>}>
      <FramerMotionHeader {...props} className={props?.className}>
        {children}
      </FramerMotionHeader>
    </Suspense>
  );
};

const FramerMotionP = React.lazy(() => import('framer-motion').then((module) => ({ default: module.motion.p })));

export const MotionP = ({ children, ...props }: any) => {
  if (isIE) {
    return (
      <p {...props} className={props?.className}>
        {children}
      </p>
    );
  }
  return (
    <Suspense fallback={<div></div>}>
      <FramerMotionP {...props} className={props?.className}>
        {children}
      </FramerMotionP>
    </Suspense>
  );
};

const FramerMotionHeader4 = React.lazy(() => import('framer-motion').then((module) => ({ default: module.motion.h4 })));

export const MotionH4 = ({ children, ...props }: any) => {
  if (isIE) {
    return (
      <h4 {...props} className={props?.className}>
        {children}
      </h4>
    );
  }
  return (
    <Suspense fallback={<div></div>}>
      <FramerMotionHeader4 {...props} className={props?.className}>
        {children}
      </FramerMotionHeader4>
    </Suspense>
  );
};

const FramerImage = React.lazy(() => import('framer-motion').then((module) => ({ default: module.motion.img })));

export const MotionImage = (props: any) => {
  if (isIE) {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...props} className={props?.className} />;
  }
  return (
    <Suspense fallback={<div></div>}>
      <FramerImage {...props} className={props.className} />
    </Suspense>
  );
};

const FramerSpan = React.lazy(() => import('framer-motion').then((module) => ({ default: module.motion.span })));

export const MotionSpan = ({ children, ...props }: any) => {
  if (isIE) {
    return (
      <span {...props} className={props?.className}>
        {children}
      </span>
    );
  }
  return (
    <Suspense fallback={<div></div>}>
      <FramerSpan {...props} className={props?.className}>
        {children}
      </FramerSpan>
    </Suspense>
  );
};

export default MotionDiv;
