import React from 'react';
import { isSafari, isIOS } from 'react-device-detect';
import { Button as BootstrapButton } from 'react-bootstrap';
import { ButtonProps } from './types';

const Button = ({
  children,
  isLoading,
  iconBefore,
  className,
  style,
  iconOnly,
  icon,
  withArrow,
  ...props
}: ButtonProps) => {
  return (
    <React.Fragment>
      <BootstrapButton
        {...props}
        className={`${!iconBefore && !iconOnly ? 'btn-icon-after' : ''} ${
          iconBefore && !iconOnly ? 'btn-icon-before' : ''
        } 
        ${iconOnly ? 'btn-icon-only' : ''} ${icon ? `btn-icon btn-icon-${icon}` : ''} ${className || ''} ${
          withArrow ? `btn-arrow` : ''
        }
         ${isLoading ? 'btn-loading' : ''} ${isSafari && !isIOS ? 'btn-safari' : ''}`}
        style={style}
      >
        {children && <span style={{ opacity: isLoading ? 0.1 : 1 }}>{children}</span>}
        {isLoading && <div className="btn-loader"></div>}
      </BootstrapButton>
    </React.Fragment>
  );
};

export default Button;
