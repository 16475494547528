import { uploadDataUrlToAzure } from './UploadToAzure';
const maxHeight = 191 * 2;
const maxWidth = 302 * 2;
const maxTime = 20000;

const createAndUploadThumbnail = (file: File, onSuccess: (imageUrl: string) => void) => {
  return new Promise<string | null>((resolve, reject) => {
    const timeout = setTimeout(resolve, maxTime);
    const resolveAndCancelTimer = () => {
      clearTimeout(timeout);
      resolve(null);
    };
    try {
      var reader = new FileReader();
      reader.onload = function (event) {
        var img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        img.onload = function () {
          try {
            if (
              file.type !== 'image/gif' &&
              ((img.height > img.width && img.height <= maxHeight) || (img.width > img.height && img.width <= maxWidth))
            ) {
              resolveAndCancelTimer();
            } else {
              if (
                (img.height > img.width && img.height <= maxHeight) ||
                (img.width > img.height && img.width <= maxWidth)
              ) {
                canvas.height = img.height;
                canvas.width = img.width;
              } else if (img.height > img.width) {
                canvas.height = maxHeight;
                canvas.width = (img.width / img.height) * maxHeight;
              } else {                
                canvas.height = (img.height / img.width) * maxWidth;
                canvas.width = maxWidth;
              }

              ctx!.drawImage(img, 0, 0, canvas.width, canvas.height);
              const imageDataUrl = canvas.toDataURL('image/jpeg', 0.8);
              uploadDataUrlToAzure(imageDataUrl, 'thumbnail.jpg').then((uploadResult) => {
                onSuccess(uploadResult.url);
                clearTimeout(timeout);
                resolve(uploadResult.url);
              });
            }
          } catch {
            resolveAndCancelTimer();
          }
        };
        img.onerror = resolveAndCancelTimer;
        if (typeof event.target!.result === 'string') {
          img.src = event.target!.result;
        } else {
          resolveAndCancelTimer();
        }
      };
      reader.readAsDataURL(file);
    } catch {
      resolveAndCancelTimer();
    }
  });
};

export default createAndUploadThumbnail;
