// LOGIN/SIGNUP
export const signUpUrl = 'api/app/user';
export const getFetchUserUrl = (userId: string) => `api/app/user/${userId}`;
// SIGNED IN FORM
export const uploadImageUrl = 'api/app/mediaitem';
// FORM
export const getPresignedUrlUrl = (fileName: string) => `api/app/uploads/presignedUrl?fileName=${fileName}`;
// GALLERY
export const getGalleryUrl = (page: number) => `api/app/gallery/page?pagenumber=${page}`;
export const getHeartItemUrl = (id: string) => `api/app/heart/${id}/heartitem`;
export const promotedGalleryUrl = 'api/app/mediaitem/successpageimages';
// WATCH AND WIN
export const watchPremiereUrl = 'api/app/watchpremiere/premiere';
export const enterWatchPremiereUrl = 'api/app/watchpremiere/enter';
export const winPremiereUrl = 'api/app/winpremiere/premiere';
export const enterWinPremiereUrl = 'api/app/winpremiere/enter';
// LANDING PAGE
export const totalApprovedHeartsUrl = 'api/app/mediaItem/totalApprovedHearts';
export const countdownSecondsRemainingUrl = 'api/app/shared/countdownClockSecondsRemaining';
// MAP
export const mapFeedUrl = 'api/app/mediaitemfeed/feed';
// PRIZES
export const getCurrentPrizeUrl = 'api/app/prize/prizes';
