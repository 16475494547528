export interface EventData {
  action: string;
  category: string;
  label?: string;
}

type EventTrackingMap = {
  [eventDataKey in
    | 'LoveuaryLogoClick'
    | 'LogoutButtonClick'
    | 'EnterNowButtonClick'
    | 'ViewGalleryClickLandingPage'
    | 'EarnBonusEntriesButtonClick'
    | 'AddAnotherHeartButtonClick'
    | 'DisclaimerClickHereClick'
    | 'MediaFormUploadBeginSignUpPage'
    | 'MediaFormUploadSuccessSignUpPage'
    | 'MediaFormUploadErrorSignUpPage'
    | 'MediaFormUploadRemoveSignUpPage'
    | 'SignUpFormSuccess'
    | 'SignUpFormError'
    | 'OfficialRulesClick'
    | 'TermsOfUseClick'
    | 'PrivacyPolicyClick'
    | 'ConfirmButtonClickSignUpPage'
    | 'DownloadNowButtonClick'
    | 'WatchPreviewButtonClick'
    | 'VideoPlay'
    | 'HeartNumberGuessConfirmClick'
    | 'HeartNumberGuessCorrect'
    | 'HeartNumberGuessIncorrect'
    | 'HeartNumberGuessError'
    | 'HeartNumberGuessSuccess'
    | 'ViewGalleryClickSuccessPage'
    | 'HeartButtonClick'
    | 'BackButtonClick'
    | 'NextButtonClick'
    | 'PreviousButtonClick'
    | 'NotSignedInModalView'
    | 'ModalSignUpButtonClick'
    | 'ModalBackToGalleryButtonClick'
    | 'MediaFormUploadBeginUploadPage'
    | 'MediaFormUploadSuccessUploadPage'
    | 'MediaFormUploadErrorUploadPage'
    | 'MediaFormUploadRemoveUploadPage'
    | 'UploadFormConfirmButtonClick'
    | 'UploadFormConfirmSuccess'
    | 'UploadFormConfirmError'
    | 'ViewPrizesClick'
    | 'ViewPrizesClose']: EventData;
};

//Pages
const landingPage = 'Landing Page';
const signupPage = 'Sign Up Page';
const successPage = 'Success Page';
const rewardsPage = 'Rewards Page';
const galleryPage = 'Gallery Page';
const uploadPage = 'Upload Page';
const rulesPage = 'Rules Page';
const signedOutModal = 'Signed Out Modal';
const footer = 'Footer';
const header = 'Header';
const signedInNavBar = 'Signed In Nav Bar';
const viewPrizesModal = 'View Prizes Modal';

const EventTrackingData: EventTrackingMap = {
  LoveuaryLogoClick: {
    action: 'Loveuary Logo Click',
    category: header,
    label: '',
  },
  LogoutButtonClick: {
    action: 'Logout Button Click',
    category: signedInNavBar,
    label: '',
  },
  EnterNowButtonClick: {
    action: 'Enter Now Button Click',
    category: landingPage,
    label: '',
  },
  ViewGalleryClickLandingPage: {
    action: 'View Gallery Click - Landing Page',
    category: landingPage,
    label: '',
  },
  OfficialRulesClick: {
    action: 'Official Rules Click',
    category: signupPage,
    label: '',
  },
  TermsOfUseClick: {
    action: 'Terms of Use Click',
    category: signupPage,
    label: '',
  },
  PrivacyPolicyClick: {
    action: 'Privacy Policy Click',
    category: signupPage,
    label: '',
  },
  EarnBonusEntriesButtonClick: {
    action: 'Earn Bonus Entries Button Click',
    category: landingPage,
    label: '',
  },
  AddAnotherHeartButtonClick: {
    action: 'Add Another Heart Button Click',
    category: landingPage,
    label: '',
  },
  DisclaimerClickHereClick: {
    action: 'Disclaimer Click Here Click',
    category: footer,
    label: '',
  },
  SignUpFormSuccess: {
    action: 'Sign Up Form Success',
    category: signupPage,
    label: '',
  },
  ViewPrizesClick: {
    action: 'View Prizes Click',
    category: landingPage,
    label: '',
  },
  ViewPrizesClose: {
    action: 'View Prizes Close',
    category: viewPrizesModal,
    label: '',
  },
  SignUpFormError: {
    action: 'Sign Up Form Error',
    category: signupPage,
    label: '',
  },
  MediaFormUploadBeginSignUpPage: {
    action: 'Media Upload Form Begin - Signup Page',
    category: signupPage,
    label: '',
  },
  MediaFormUploadSuccessSignUpPage: {
    action: 'Media Upload Form Success - Signup Page',
    category: signupPage,
    label: '',
  },
  MediaFormUploadErrorSignUpPage: {
    action: 'Media Upload Form Error - Signup Page',
    category: signupPage,
    label: '',
  },
  MediaFormUploadRemoveSignUpPage: {
    action: 'Media Upload Form Remove - Signup Page',
    category: signupPage,
    label: '',
  },
  ConfirmButtonClickSignUpPage: {
    action: 'Confirm Button Click - Signup Page',
    category: signupPage,
    label: '',
  },
  DownloadNowButtonClick: {
    action: 'Download Now Button Click',
    category: successPage,
    label: '',
  },
  WatchPreviewButtonClick: {
    action: 'Watch Preview Button Click',
    category: successPage,
    label: '',
  },
  VideoPlay: {
    action: 'Video Played',
    category: successPage,
    label: '',
  },
  HeartNumberGuessConfirmClick: {
    action: 'Guess Hearts Number Confirm Button Click',
    category: successPage,
    label: '',
  },
  HeartNumberGuessCorrect: {
    action: 'Correct Answer Hearts Number',
    category: successPage,
    label: '',
  },
  HeartNumberGuessIncorrect: {
    action: 'Incorrect Answer Hearts Number',
    category: successPage,
    label: '',
  },
  HeartNumberGuessError: {
    action: 'Heart Guess Error',
    category: successPage,
    label: '',
  },
  HeartNumberGuessSuccess: {
    action: 'Heart Guess Success',
    category: successPage,
    label: '',
  },
  ViewGalleryClickSuccessPage: {
    action: 'View Gallery Click - Success Page',
    category: successPage,
    label: '',
  },
  HeartButtonClick: {
    action: 'Heart Button Click',
    category: galleryPage,
    label: '',
  },
  BackButtonClick: {
    action: 'Back Button Click',
    category: galleryPage,
    label: '',
  },
  NextButtonClick: {
    action: 'Next Page Click',
    category: galleryPage,
    label: '',
  },
  PreviousButtonClick: {
    action: 'Previous Page Click',
    category: galleryPage,
    label: '',
  },
  NotSignedInModalView: {
    action: 'View Not Signed-In Modal',
    category: galleryPage,
    label: '',
  },
  ModalSignUpButtonClick: {
    action: 'Sign Up Button Click on Modal',
    category: signedOutModal,
    label: '',
  },
  ModalBackToGalleryButtonClick: {
    action: 'Back to Gallery Button Click on Modal',
    category: signedOutModal,
    label: '',
  },
  MediaFormUploadBeginUploadPage: {
    action: 'Media Form Upload Begin - Upload Page',
    category: uploadPage,
    label: '',
  },
  MediaFormUploadSuccessUploadPage: {
    action: 'Media Form Upload Success - Upload Page',
    category: uploadPage,
    label: '',
  },
  MediaFormUploadErrorUploadPage: {
    action: 'Media Form Upload Error - Upload Page',
    category: uploadPage,
    label: '',
  },
  MediaFormUploadRemoveUploadPage: {
    action: 'Media Form Remove Error - Upload Page',
    category: uploadPage,
    label: '',
  },
  UploadFormConfirmButtonClick: {
    action: 'Upload Form Confirm Button Click - Upload Page',
    category: uploadPage,
    label: '',
  },
  UploadFormConfirmSuccess: {
    action: 'Upload Form Confirm Success - Upload Page',
    category: uploadPage,
    label: '',
  },
  UploadFormConfirmError: {
    action: 'Upload Form Confirm Error - Upload Page',
    category: uploadPage,
    label: '',
  },
};

// const keylist = Object.keys(EventTrackingData).reduce((acc, x) => acc + `| '${x}'`, '');
// console.log(keylist);

export default EventTrackingData;
