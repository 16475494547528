import React, { useEffect } from 'react';
import AdSlot from './AdSlot';
import useFreewheel from '../../infrastructure/freewheel/useFreewheel';
import { useLocation } from 'react-router';

const FooterAd: React.FC<{ shouldDisplay: boolean; classNames: string }> = ({ shouldDisplay, classNames }) => {
  const freewheelRequest = useFreewheel();
  const location = useLocation();
  useEffect(() => {
    if (shouldDisplay) {
      freewheelRequest(location.pathname);
    }
  }, [location, freewheelRequest, shouldDisplay]);
  if (!shouldDisplay) {
    return null;
  }
  return <AdSlot width={300} height={250} slotId="fixed_ad" classNames={classNames} />;
};

export default FooterAd;
