import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GalleryRoute } from '../../constants/Routes';
import { trackPageView } from 'infrastructure/tracking/GoogleAnalytics';
import { LandingRoute } from 'constants/Routes';
import { Container, Row, Col } from 'react-bootstrap';
import { Button, Loader } from 'components/Layout';
import { getGalleryUrl } from 'constants/ApiUrls';
import GetConfig from 'helpers/GetConfig';
import { useAppSelector, useAppDispatch } from '../../hooks/useReduxHelper';
import TwitterButton from 'components/Layout/TwitterButton';
import PinterestButton from 'components/Layout/PinterestButton';
import FacebookButton from 'components/Layout/FacebookButton';
import { StackedLogo } from 'images/logos';
import EventTrackingData from 'constants/EventTracking';
import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import { loadSingleImage } from '../../hooks/useImages';
import GalleryItem from '../Gallery/GalleryItem';
import CallApi from 'infrastructure/api/CallApi';
import MotionDiv from 'components/Shared/MotionDiv';
import { IGalleryItemDto, IGalleryPageDto } from 'types/Gallery';
import { LoveuaryLogo } from 'images/logos';
import { addPageItems, changePage, selectIsLastPage, selectItemCount, selectItems } from 'slices/Gallery/GallerySlice';
import { GalleryItemsPerPage } from 'constants/index';
import LoveuaryLogoCol from '../Hallmark/LoveuaryLogoCol';
import SignInToLikeModal from './SignInToLikeModal';

const GalleryPage: React.FC = () => {
  const page = useAppSelector((state) => state.gallery.page);
  const loadedItemCount = useAppSelector(selectItemCount);
  const items = useAppSelector(selectItems);
  const isLastPage = useAppSelector(selectIsLastPage);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);

  const loadImages: (imageItems: Array<IGalleryItemDto>) => void = (imageItems) => {
    setImagesLoaded(false);
    const imageLoadingPromises = imageItems.map((e) => e.thumbnailUrl || e.originalUrl).map(loadSingleImage);
    Promise.all(imageLoadingPromises).finally(() => {
      setImagesLoaded(true);
    });
    setTimeout(() => {
      setImagesLoaded(true);
    }, 5000);
  };

  useEffect(() => {
    // CHECK IF WE ALREADY HAVE THE CONTENT - CALL API IF WE DON'T
    if (loadedItemCount <= (page - 1) * GalleryItemsPerPage) {
      CallApi<IGalleryPageDto>(getGalleryUrl(page)).then(({ data }) => {
        dispatch(addPageItems({ ...data }));
        loadImages(data.items);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
      setImagesLoaded(true);
    }
    trackPageView(`Gallery page - ${page}`, `${GalleryRoute}/${page}`);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (showModal) {
      trackEventData(EventTrackingData.NotSignedInModalView);
    }
  }, [showModal]);

  return (
    <>
      <div className="container">
        <div className="content-wrapper gallery-index">
          <Row>
            <LoveuaryLogoCol />
          </Row>
          <Link to={LandingRoute}>
            <Button
              variant="link"
              withArrow
              iconBefore
              size="sm"
              onClick={() => trackEventData(EventTrackingData.BackButtonClick)}
            >
              Back
            </Button>
          </Link>
          <h1>Gallery of Hearts</h1>

          <p className="gallery-header">Heart your favorite romantic snaps to get more entries!</p>
        </div>
      </div>

      {showModal && <SignInToLikeModal show={showModal} onHide={() => setShowModal(false)} />}

      <Container>
        <MotionDiv initial={{ y: '10%', opacity: 0 }} animate={{ y: '0', opacity: 1 }} transition={{ duration: 0.5 }}>
          <div className="card-gallery">
            {isLoading || !imagesLoaded ? (
              <Loader></Loader>
            ) : (
              <Row className="gallery-container-grid">
                {items.map((item, i) => (
                  <GalleryItem item={item} key={item.id} likeButtonPlacement="below" setShowModal={setShowModal} />
                ))}
              </Row>
            )}

            <Row className="gallery-nav">
            <Col lg={12}>{!isLoading && page >= 1 && <div className="page-counter">Page {page}</div>}</Col>
              <Col sm={12} className="mobile-button">
                {!isLoading && page !== 1 && (
                  <Button
                    className="btn-outline-primary mobile-nav"
                    onClick={() => {
                      trackEventData({ ...EventTrackingData.PreviousButtonClick, label: (page - 1).toString() });
                      dispatch(changePage(page - 1));
                    }}
                    disabled={isLoading || page === 1}
                    withArrow
                    iconBefore
                  ></Button>
                )}
                {!isLoading && !isLastPage && (
                  <Button
                    className="btn-outline-primary mobile-nav"
                    onClick={() => {
                      trackEventData({ ...EventTrackingData.NextButtonClick, label: (page + 1).toString() });
                      dispatch(changePage(page + 1));
                    }}
                    disabled={isLoading || isLastPage}
                    withArrow
                  ></Button>
                )}
              </Col>
              <Col lg={4} className="desktop-button">
                {!isLoading && page !== 1 && (
                  <Button
                    className="btn-outline-primary"
                    onClick={() => {
                      trackEventData({ ...EventTrackingData.PreviousButtonClick, label: (page - 1).toString() });
                      dispatch(changePage(page - 1));
                    }}
                    disabled={isLoading || page === 1}
                    withArrow
                    iconBefore
                  >
                    Previous
                  </Button>
                )}
              </Col>
              <Col lg={4} className="desktop-button">
                {!isLoading && !isLastPage && (
                  <Button
                    className="btn-outline-primary"
                    onClick={() => {
                      trackEventData({ ...EventTrackingData.NextButtonClick, label: (page + 1).toString() });
                      dispatch(changePage(page + 1));
                    }}
                    disabled={isLoading || isLastPage}
                    withArrow
                  >
                    Next
                  </Button>
                )}
              </Col>

            </Row>
          </div>
        </MotionDiv>
      </Container>
    </>
  );
};

export default GalleryPage;
