import EventTrackingData from 'constants/EventTracking';
import PageTrackingData from 'constants/PageViewTracking';
import { SignUpFormRoute } from 'constants/Routes';
import { usePageView } from 'hooks';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Layout';

interface ISignInToLikeModalProps {
  onHide: () => void;
  show: boolean;
}

const SignInToLikeModal: React.FC<ISignInToLikeModalProps> = ({ onHide, show }) => {
  const navigate = useNavigate();
  usePageView(PageTrackingData.NotSignedInModalData);

  return (
    <Modal show={show} centered onHide={onHide} className="reentry">
      <Modal.Header className="reentry-modal-header">Welcome!</Modal.Header>

      <Modal.Body>
        <div className="modal-cta">
          <span className="modal-reward-copy">Sign-up to heart your favorite entries and submit your own!</span>
          <Button
            variant="primary"
            withArrow
            onClick={() => {
              navigate(SignUpFormRoute);
              trackEventData(EventTrackingData.ModalSignUpButtonClick);
            }}
          >
            Sign Up
          </Button>
          <Button
            variant="link"
            onClick={() => {
              onHide();
              trackEventData(EventTrackingData.ModalBackToGalleryButtonClick);
            }}
          >
            Back to gallery
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignInToLikeModal;
