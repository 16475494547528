import { trackPageView } from './GoogleAnalytics';
import { PageData } from '../../constants/PageViewTracking';

const trackPageViewGoogle: (pageTitle: string, pagePath: string) => void = (pageTitle, pagePath) => {
  trackPageView(pageTitle, pagePath);
};

export const trackPageViewGoogleWithPageData: (pageData: PageData) => void = ({ title, path }) => {
  trackPageView(title, path);
};

export default trackPageViewGoogle;
