const stateLocations = {
  Texas: [45, 70],
  California: [8, 48],
  Colorado: [34, 45],
  Wyoming: [32, 31],
  Washington: [13, 12],
  Oregon: [10, 23],
  Nevada: [14, 39],
  Idaho: [21, 25],
  Utah: [24, 42],
  Arizona: [21, 58],
  Montana: [30, 16],
  NewMexico: [32, 59],
  NorthDakota: [44, 18],
  SouthDakota: [44, 27],
  Nebraska: [44, 37],
  Kansas: [46, 47],
  Oklahoma: [49, 56],
  Minnesota: [53, 22],
  Iowa: [55, 35],
  Missouri: [57, 47],
  Arkansas: [58, 60],
  Louisiana: [58, 72],
  Wisconsin: [61, 26],
  Illinois: [62, 40],
  Mississippi: [63, 66],
  Michigan: [69, 28],
  Indiana: [67.5, 41],
  Kentucky: [71, 48],
  Tennessee: [68, 54],
  Alabama: [69, 65],
  Ohio: [73, 38],
  Georgia: [75, 64],
  SouthCarolina: [79, 59],
  WestVirginia: [77, 44],
  Pennsylvania: [81, 34],
  Florida: [81, 80],
  NorthCarolina: [82, 52],
  Virginia: [83, 44],
  Maryland: [84, 38],
  NewYork: [85, 26],
  Delaware: [87, 39],
  NewJersey: [87, 34],
  Vermont: [87.5, 20],
  Connecticut: [89, 28],
  RhodeIsland: [91, 27],
  Massachusetts: [90, 25],
  NewHampshire: [90, 21],
  Maine: [93, 13],
  Alaska: [9, 75],
  Hawaii: [16, 87.5],
};

export default stateLocations;
