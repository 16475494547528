import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useAppSelector } from 'hooks/useReduxHelper';
import { selectIsSignedIn } from 'slices/User/UserSlice';
import { useWindowDimensions } from 'hooks';

interface IContentWrapperProps {
  children: React.ReactNode;
}

const ContentWrapper: React.FunctionComponent<IContentWrapperProps> = ({ children }) => {
  const location = useLocation();
  const { windowWidth } = useWindowDimensions();
  const showNavBar = useAppSelector(state => state.user.showNavBar && Boolean(state.user.id));

  return (
    <div id="content-wrap" className={classNames({ 'premiere-page': location.pathname.startsWith('/premiere'), 'no-menu': !showNavBar || windowWidth >= 992 })}>
      {children}
    </div>
  );
};

export default ContentWrapper;
