import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from 'infrastructure/routes/Routes';
import { HallmarkHeader, HallmarkFooter } from 'components/Hallmark';
import {
  shouldDisplayDesktopAnimatedBannerAd,
  shouldDislayDesktopTowerAd,
  shouldDisplayMobileFooterAd,
} from 'infrastructure/freewheel/breakpoints';
import { NavBar, Disclaimer } from 'components/Layout';
import './styles/style.scss';
import { isIE } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHelper';
import { setIsWebView } from 'slices/WebView/WebViewSlice';
import FixedAd from 'components/Ads/FixedAd';
import AnimatedBanner from 'components/Ads/AnimatedBanner';
import ContentWrapper from './components/Layout/ContentWrapper';
import GetConfig from 'helpers/GetConfig';
import ClosedRoutes from './infrastructure/routes/ClosedRoutes';
import { IUserDto } from './types/User';
import CallApi from 'infrastructure/api/CallApi';
import { getCurrentPrizeUrl, getFetchUserUrl } from 'constants/ApiUrls';
import { logout, updateUser } from 'slices/User/UserSlice';
import { IPrizeDto } from 'types/Prizes';
import { addCurrentPrize } from 'slices/Prizes/PrizeSlice';
import { onExceptionTestForClosed } from 'helpers';

const { competitionClosed } = GetConfig();
function App() {
  const { id: userId } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (window.location.href.toLowerCase().includes('hmc=1')) {
      dispatch(setIsWebView(true));
    }
    if (!competitionClosed) {
      if (userId) {
        CallApi<IUserDto>(getFetchUserUrl(userId))
          .then(({ data }) => {
            dispatch(updateUser(data));
          })
          .catch((e: any) => {
            console.log(e.response.data.error.code);
            if (
              e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.code === 'CustomError.NullUser'
            ) {
              dispatch(logout());
            }
          });
      }
      // CallApi<IPrizeDto>(getCurrentPrizeUrl).then(({ data }) => {
      //   dispatch(addCurrentPrize(data));
      // }).catch(onExceptionTestForClosed);
    } else if (userId) {
      dispatch(logout());
    }

    const ieClass = isIE ? 'isIE' : 'notIE';
    document.body.classList.add(ieClass);
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <ContentWrapper>
        <React.Fragment>
          <HallmarkHeader />
          <NavBar />
          {competitionClosed ? <ClosedRoutes /> : <AppRoutes />}
          <Disclaimer />
          <FixedAd shouldDisplay={shouldDislayDesktopTowerAd} />
          <AnimatedBanner shouldDisplayDesktopVersion={shouldDisplayDesktopAnimatedBannerAd} />
        </React.Fragment>
      </ContentWrapper>
      <div id="footer">
        <HallmarkFooter shouldDisplay={shouldDisplayMobileFooterAd} />
      </div>
    </Router>
  );
}

export default App;
