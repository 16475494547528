import React from 'react';
import { useAppSelector } from 'hooks/useReduxHelper';
import { selectIsSignedIn } from 'slices/User/UserSlice';
import { Logo } from 'components/Layout';
import { usePageView } from 'hooks';
import PageData from 'constants/PageViewTracking';
import { Container, Row, Col } from 'react-bootstrap';
import { MotionH1 } from 'components/Shared/MotionDiv';
import MotionDiv from 'components/Shared/MotionDiv';
import LoveuaryLogoCol from 'components/Hallmark/LoveuaryLogoCol';

const RulesPage: React.FC = () => {
  usePageView(PageData.RulesPageData);

  return (
    <Container className="page page-rules landing_main">
      <MotionDiv initial={{ y: '10%', opacity: 0 }} animate={{ y: '0', opacity: 1 }} transition={{ duration: 0.5 }}>
        <LoveuaryLogoCol />
        <Row className="section-header ">
          <Col xs={12}>
            <MotionH1>
              <strong>Hallmark Channel® Loveuary Sweepstakes</strong>
              <br />
              OFFICIAL RULES
            </MotionH1>
          </Col>
        </Row>
        <Row className="rules-row-body">
          <Col>
            <p>
              <strong>NO PURCHASE NECESSARY TO ENTER OR WIN.</strong>
              <br />
              <br />
              <strong>A PURCHASE WILL NOT INCREASE YOUR CHANCE OF WINNING. VOID WHERE PROHIBITED.</strong>
              <br />{' '}
              <ol>
                <li>
                  <strong>SPONSOR:</strong> Crown Media United States, LLC. 12700 Ventura Boulevard, Studio City, CA
                  91604 ("<strong>Sponsor</strong>").
                  <br /> <strong>ADMINISTRATOR:</strong> Ventura Associates International LLC, 494 Eighth Avenue, New
                  York, NY 10001 (“Administrator”), an independent judging organization whose decisions are final and
                  binding with respect to the Promotion.{' '}
                </li>
                <br />
                <li>
                  <strong>ELIGIBILITY:</strong> Promotion is open only to persons, who are, as of date of entry, 21
                  years of age or older, legal residents of, and physically located within, the 50 United States or
                  D.C., except that employees, officers and directors and the members of their households and their
                  immediate family members (defined as parents, children and their spouses)) of Sponsor, each of their
                  parent, subsidiary and affiliated companies, and the advertising, promotional and fulfillment agencies
                  of each of them are not eligible to enter or win. “Immediate Family” members shall mean parents,
                  step-parents, grandparents, children, step-children, siblings, step-siblings, or spouses, regardless
                  of where they live. “Household members” shall mean people who share the same residence at least three
                  (3) months a year, whether related or not. Entrants must be willing to have the Entry (as defined
                  below in Section 4) appear in the gallery of{' '}
                  <a href="https://loveuarysweepstakes.hallmarkchannel.com">loveuarysweepstakes.hallmarkchannel.com</a>{' '}
                  (“
                  <strong>Website</strong>”), possibly published on Sponsor’s social media platforms, possibly shown
                  on-air on the Hallmark Channel or otherwise distributed, in connection with, or to promote, the
                  Contest and/or Website and/or for any other commercial or other purpose as determined by Sponsor.
                  <br />
                  <br />
                  <strong>
                    <u>Entrants may not have been convicted of a felony.</u>
                  </strong>{' '}
                  By entering, each entrant understands and agrees that, if selected as a potential Grand Prize Winner
                  (defined in Section 7 below), the entrant (and any adult member of his/her Immediate Family) will be
                  required to consent to a background check; and, failure to sign such consents and authorizations
                  and/or to furnish all required information will result in disqualification. In addition, Sponsor
                  reserves the right in its sole discretion, to disqualify any Entry (as defined in Section 4 below)
                  and/or disqualify a potential Grand Prize Winner if the results of such background check reveal that
                  the potential Grand Prize Winner is not eligible to participate in the Promotion or has been convicted
                  of, or is currently charged with, a felony or a crime of moral turpitude, as determined by Sponsor in
                  its sole discretion. Any other adult member of the Grand Prize Winner’s household who is participating
                  in the Grand Prize (defined in Section 7) will also be required to consent to a background check, and
                  failure to sign such consents and authorizations and/or to furnish all required information will
                  result in the potential Grand Prize Winner being disqualified, and the Grand Prize being forfeited. In
                  addition, Sponsor reserves the right, in its sole discretion, to disqualify a potential Grand Prize
                  Winner if the results of such background check reveal that the additional adult member of the Grand
                  Prize Winner’s household who is participating in the Grand Prize has been convicted of a felony or a
                  crime of moral turpitude, as determined by Sponsor in its sole discretion, in which case the Grand
                  Prize will be forfeited, and Sponsor will have no liability of any kind (either to a potential Grand
                  Prize Winner, or additional adult member of the Grand Prize Winner’s household who is participating in
                  the Grand Prize or any third party).
                </li>{' '}
                <br />
                <li>
                  <strong>ENTRY PERIOD:</strong> You may enter this sweepstakes via the internet during the period
                  beginning at 10:00:00 A.M. Eastern Time ("<strong>ET</strong>") on 1/24/22 and ends at 11:59:59 P.M.
                  ET on 2/28/22 (“
                  <strong>Master Entry Period</strong>”). The Promotion will consist of one (1) grand prize sweepstakes
                  (“
                  <strong>Grand Prize Sweepstakes</strong>”) and five (5) weekly sweepstakes (“
                  <strong>Weekly Sweepstakes</strong>”) with five (5) entry periods (each a “
                  <strong>Weekly Entry Period</strong>”) as noted in the chart below. The Promotion will consist of one
                  (1) grand prize (“<strong>Grand Prize</strong>”) and fifty-two (52) weekly prizes (“
                  <strong>Weekly Prize(s)</strong>”). Only those eligible entries received during a specific Weekly
                  Entry Period by the applicable end date as listed in the chart below will be entered into that week’s
                  Weekly Sweepstakes. Any entries received during a particular Weekly Entry Period will not be included
                  in the subsequent weekly drawings; however, all eligible entries for the Weekly Sweepstakes will roll
                  over into the Grand Prize Sweepstakes. A “Week” is defined as starting at 12:00:00am ET on each Monday
                  during the Master Entry Period and ending at 11:59:59pm ET the following Sunday during the Master
                  Entry Period except the first day of the first week which will start at 10:00:00am ET and the last day
                  of the fifth week which will end on the following Monday.
                  <table>
                    {' '}
                    <tr>
                      <th></th>
                      <th colSpan={2}>Weekly Entry Period</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>
                        <strong>Weekly Sweeps</strong>
                      </td>
                      <td>
                        <strong>Start [each Monday at 12am</strong> except the first day of the First Week which will
                        start at 10:00:00am ET.]
                      </td>
                      <td>
                        <strong>End (the following Sunday at 11:59:59pm ET)</strong>
                      </td>
                      <td>
                        <strong>Winners</strong>
                      </td>
                      <td>
                        <strong>Prize</strong>
                      </td>
                      <td>
                        <strong>ARV</strong>
                      </td>
                      <td>
                        <strong>Approximate Drawing Date (on or about)</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Week 1</td>
                      <td>1/24/22</td>
                      <td>1/30/22</td>
                      <td>20</td>
                      <td>
                        Hallmark Valentine Prizing – includes Better Together Strawberry & Chocolate Magnetic Plush;
                        Pierced Heart Mug, 2x Video Greeting Cards and Date Night Jar
                      </td>
                      <td>$61.95</td>
                      <td>2/7/2022</td>
                    </tr>
                    <tr>
                      <td>Week 2</td>
                      <td>1/31/22</td>
                      <td>2/6/22</td>
                      <td>5</td>
                      <td>
                        Date Night Kit – Includes $100 Gift Card, 2x Robes, 2x Slippers, Blanket, Popcorn Bowl, # Month
                        HMNow Subscription, Candle Set and Box of Chocolates/Chocolate Covered Strawberries
                      </td>
                      <td>$325.92</td>
                      <td>2/14/2022</td>
                    </tr>
                    <tr>
                      <td>Week 3</td>
                      <td>2/7/22</td>
                      <td>2/13/22</td>
                      <td>25</td>
                      <td>
                        Galentine’s Day Prize Basket – Includes HP-A Cottage Wedding, HP-A Waterfront Wedding, $25
                        Hallmark Channel Wines Gift Certificate, 2x The Wedding Veil Lace Umbrella, 2x Happily Ever
                        After Stemless Wine Glasses and Bissinger’s Valentine’s Chocolate
                      </td>
                      <td>$212.98</td>
                      <td>2/21/2022</td>
                    </tr>
                    <tr>
                      <td>Week 4</td>
                      <td>2/14/22</td>
                      <td>2/20/22</td>
                      <td>1</td>
                      <td>
                        Wedding Veil Trilogy Prizing – Includes Veil from The Wedding Vail Trilogy (prize is a prop from
                        the show).
                      </td>
                      <td>$300</td>
                      <td>2/28/2022</td>
                    </tr>
                    <tr>
                      <td>Week 5</td>
                      <td>2/21/22</td>
                      <td>2/28/22</td>
                      <td>1</td>
                      <td>Flowers – Flowers for a year, monthly deliveries. Awarded as a check.</td>
                      <td>$750</td>
                      <td>3/7/2022</td>
                    </tr>
                    <tr>
                      <td>Grand Prize</td>
                      <td>1/24/22</td>
                      <td>2/28/22</td>
                      <td>1</td>
                      <td>Home Pampering – Personal Butler for a Month. Awarded as a check. </td>
                      <td>$10,000</td>
                      <td>3/7/2022</td>
                    </tr>{' '}
                  </table>
                </li>
                <br />
                <li>
                  <strong>HOW TO ENTER:</strong> To enter, visit Website and follow the instructions to complete the
                  entry in its entirety. <br />
                  <br />
                  <strong>Initial Entry:</strong> Submit a photo of yourself doing “heart hands” (“
                  <strong>Photo</strong>”). You may, but you do not have to, include your body/face in the Photo. Once
                  you have created your Photo, submit your Entry (as defined below) by visiting the Website and by
                  following these steps: <strong>(a)</strong> please enter your first and last name along with your own
                  email address and zip code (required) and you may also include your cable provider in the drop down
                  menu (optional); <strong>(b)</strong> click the “Enter Now” button or other similar button to upload
                  your Photo and <strong>(c)</strong> submit (“<strong>Entry</strong>”). Upon completion, Entrant will
                  receive one (1) Entry into the Sweepstakes.{' '}
                  <strong>
                    Entrant can submit an additional Photo each day to earn additional Entries, however the Photo must
                    be different for each Entry.
                  </strong>{' '}
                  Limit one (1) Entry per day. <br />
                  <strong>
                    If Entry includes likenesses of third parties or contains elements not owned by you, you hereby
                    represent and warrant that you have obtained permission from such third parties or owners of such
                    elements.{' '}
                  </strong>
                  <br />
                  <br /> <strong>Additional Entries:</strong> Once you have completed your Entry, you may earn
                  additional entries in the following ways: (1) Watch a preview – entrants can watch a preview for a
                  Hallmark original movie premiere on the Website (“<strong>Preview Entry</strong>”). There will be a
                  new preview each of the five (5) weeks of the Promotion. Limit one (1) Preview Entry per week/five (5)
                  Preview Entries over the course of the Promotion. (2) Watch a Premiere – entrants can watch a
                  premiere, count the hearts which will appear at some point during the premiere during an on-screen
                  lower third and submit their guess on the number of hearts (“<strong>Premiere Entry</strong>”). The
                  Premieres will appear each Saturday on the Hallmark Channel at 8/7pm CT as follows: Butlers in Love
                  (1/29/22); The Wedding Veil (2/5/22); The Wedding Veil Unveiled (2/12/22); The Wedding Legacy
                  (2/19/22) and Welcome to Mama’s (2/26/22). Check local listings for times. Guess does not have to be
                  correct to earn the entry. Limit one (1) Premiere Entry per Premiere/five (5) Premiere Entries over
                  the course of the Promotion. (3) Hearting a Photo – entrants can “heart” other “heart hands” Photo
                  entries in the gallery (“
                  <strong>Other Heart Hand Entry</strong>”). Limit ten (10) Other Heart Hand Entries per day.
                  <br />
                  <br /> Entry shall refer to Entry, Preview Entry, Premiere Entry, and Other Heart Hand Entry,
                  collectively.
                  <br />
                  <br />{' '}
                  <strong>
                    Your Photo should follow these guidelines or Sponsor reserves the right to disqualify your Photo:
                  </strong>
                  <br />
                  <br />
                  <strong>
                    <u>Do’s...</u>
                  </strong>{' '}
                  <ul>
                    <li>
                      <strong>Do protect your privacy.</strong> Please avoid any last names or other personal
                      information in your Photo.
                    </li>
                  </ul>
                  <br />
                  <strong>
                    <u>Don’ts...</u>
                  </strong>{' '}
                  <ul>
                    <li>
                      <strong>Don’t show any brands.</strong> Avoid visible/recognizable use of brands (other than Crown
                      Media branded items) in the background, or anywhere in the Photo.
                    </li>{' '}
                    <li>
                      <strong>Don’t be rude or profane.</strong> Avoid rude gestures and other inappropriate stuff.
                    </li>
                  </ul>
                  <br /> The Website's database clock will be the official timekeeper for this Promotion. In the event
                  of a dispute over the identity of an entrant, the relevant Entry will be deemed submitted by the
                  authorized account holder of the e-mail address submitted at the time of entry. "Authorized account
                  holder" is defined as the natural person who is assigned to an e-mail address by an Internet access
                  provider, on-line service provider, or other organization (e.g., business, educational institution,
                  etc.) that is responsible for assigning e-mail addresses for the domain associated with the submitted
                  e-mail address. Entries with duplicate email addresses or multiple entries using different email
                  addresses for a single individual will be disqualified. Entries generated by a script, macro or other
                  automated means are void.
                </li>{' '}
                <br />
                <li>
                  <strong>SELECTION OF SWEEPSTAKES WINNERS:</strong> One (1) potential Grand Prize Winner and fifty-two
                  (52) potential Weekly Prize Winners (both defined in Section 7 below) will be determined via a random
                  drawing from all eligible Entries received by Administrator (an independent judging organization whose
                  decisions are final and binding in all matters) on or about dates listed in the chart above. The odds
                  of winning a prize will be determined by the number of eligible Entries received.{' '}
                </li>
                <br />
                <li>
                  <strong>VERIFICATION OF SWEEPSTAKES WINNERS:</strong> Potential Grand Prize Winner (as defined in
                  Section 7 below) will be notified by e-mail on or about one (1) business day (“
                  <strong>Email Notification</strong>”) from the date potential Grand Prize Winner is selected.
                  Potential Grand Prize Winner will be required to complete, sign and return an affidavit of eligibility
                  and liability release, and/or IRS Form W-9, a publicity release, and a background check permission
                  form. If a potential Grand Prize Winner fails to respond within five (5) business days of the Email
                  Notification attempt or if his/her winner notification is returned as undeliverable or unclaimed, or
                  if a potential Grand Prize Winner cannot accept or is unwilling to accept the Grand Prize for any
                  reason or cannot or does not comply with these Official Rules, or result of his/her background check
                  is not satisfactory to Sponsor or is otherwise found to be ineligible in Sponsor’s sole discretion,
                  such potential Grand Prize Winner may be subject to disqualification, and an alternate winner may be
                  selected in a random drawing from all remaining eligible Entries received and Sponsor will have no
                  liability of any kind (either to a potential Grand Prize Winner or any third party).
                  <br />
                  <br />
                  Potential Weekly Prize Winners (as defined in Section 7) will be notified by Email Notification on or
                  about one (1) business day from the date potential Weekly Prize Winners are selected.
                  <br />
                  <br />
                  Sponsor reserves the right to modify the notification procedures in connection with the selection of a
                  winner, if any. Entry and acceptance of Prize (as defined in Section 7) constitutes permission (except
                  where prohibited by law) to use each Winner's (as defined in Section 7) name, Prize won, hometown and
                  likeness for online posting and promotional purposes without prior notice or further compensation.{' '}
                </li>
                <br />
                <li>
                  <strong>PRIZES AND APPROXIMATE RETAIL VALUE (“ARV”):</strong> <br />
                  <br />
                  <strong>ONE (1) GRAND PRIZE:</strong> Grand Prize (“<strong>Grand Prize Winner</strong>”) will receive
                  the services of a personal butler for one (1) month, awarded as a check for $10,000.
                  <br />
                  <br />
                  <strong>FIFTY-TWO (52) WEEKLY PRIZES: </strong>Each Weekly Prize Winner (“
                  <strong>Weekly Prize Winner</strong>”) will receive the Weekly Prize as listed in the chart above.
                  <br />
                  <br /> Total ARV for Grand Prize: $10,000.00. Total ARV for Weekly Prizes: $9,243.10. Limit one prize
                  per person/Address. Total ARV for all prizes: $19,243.10.
                  <br />
                  <br /> Grand Prize and Weekly Prize may hereinafter be referred to as Prize. Grand Prize Winner and
                  Weekly Prize Winner may hereinafter be referred to as Winner. <br />
                  <br />
                  Winners will be solely responsible for all fees, federal, state and local taxes and any other expenses
                  relating to the use, acceptance and possession of a Prize. Winner must accept the Prize as stated or
                  Prize may be forfeited and may be awarded to an alternate Winner. Prizes are non-transferable in whole
                  or in part except at the discretion of Sponsor. No substitution or cash redemption except at the
                  discretion of Sponsor, in which case a Prize of equal or greater value will be awarded. Sponsor
                  reserves the right, to substitute a Prize (or component thereof) of equal or greater value in the
                  event a Prize is unavailable for any reason whatsoever. Winner shall bear all risk of loss or damage
                  to Prize upon being awarded to Prize winners and Sponsor does not have any obligation or
                  responsibility to replace Prize if lost, damaged or stolen. Prize will be awarded “as is” with no
                  warranty or guarantee. Sponsor has not made nor in any manner is responsible or liable for any
                  warranty, representation, or guarantee, express or implied, in fact or in law, relative to any Prize
                  thereof, including but not limited to its quality, condition, appearance, safety, performance. Sponsor
                  is not responsible for any fees, costs, or additional charges resulting from an entrant or Winner
                  entering and participating in the Promotion and/or being awarded and/or receiving and/or using a
                  Prize.
                  <br />
                  <br />
                  Any depiction of Prizes (or any part thereof) is for illustrative purposes only. Sponsor is
                  responsible only for Prize delivery and are not responsible for Prize utility, quality or otherwise.{' '}
                  <br />
                  <br />
                  Grand Prize Winner will be required to supply his/her social security number to Sponsor for Sponsor to
                  issue an IRS 1099 tax form in the value of the Prize. All Prize details are at the Sponsor’s sole
                  discretion. By accepting the Prize, the Winners agree to the release or his or her name, likeness,
                  and/or entry(ies) for potential publicity purposes, where permitted by law.{' '}
                </li>
                <br />
                <li>
                  <strong>PRIVACY:</strong> Information provided by you for this Promotion is subject to the privacy
                  policy located at www.hallmarkchannel.com/Privacy-Policy. By entering this Promotion, each entrant
                  agrees that, unless entrant otherwise opts out on the Website, Sponsor and its designees may use
                  entrant's, physical and/or e-mail address to contact entrant regarding offers and/or promotions that
                  Sponsor believes may interest entrant.{' '}
                </li>
                <br />
                <li>
                  <strong>LIMITATION OF LIABILITY AND DISPUTE RESOLUTION:</strong> Sponsor, prize providers, and their
                  respective parent, subsidiary and affiliated companies, those working on their behalf, and each of
                  their respective officers, directors, representatives, employees, agents, successors and assigns ("
                  <strong>Promotion Entities</strong>") are not responsible for injury or damage to entrants' or to any
                  other person's computer related to or resulting from participating in, or accessing or downloading
                  information in connection with, this Promotion. The Promotion Entities are not responsible for any
                  misdirected, lost, late, inaccurate or incomplete Entries (or e-mails or other communications), and
                  such Entries will be void. The Promotion Entities are not responsible for: (a) any computer,
                  telephone, cable, network, satellite, electronic or Internet hardware or software malfunctions,
                  failures, errors, delays, disconnections, or unavailability, (b) garbled or jumbled transmissions, (c)
                  service provider/Internet/web site/use net accessibility, availability, or traffic congestion, (d)
                  unauthorized human intervention or (e) the incorrect or inaccurate capture of entry information or the
                  failure to capture any such information. All Entries and other materials submitted by entrants in
                  connection with this Promotion become the property of Sponsor and will not be returned or
                  acknowledged. Proof of sending or submission will not be deemed to be proof of receipt by Sponsor. By
                  entering this Promotion, each entrant hereby agrees on his/her behalf (and on behalf of his/her heirs,
                  executors and administrators) to release, discharge defend, indemnify and hold harmless each of the
                  Promotion Entities, from any damage, injury, death, loss or other liability, either at law or equity,
                  whether known or unknown, asserted or non-asserted, that may arise from or in any way relate to any
                  entrant's participation in this Promotion or the awarding, acceptance, use or misuse of any prize. Any
                  and all disputes, claims, and causes of action arising out of or in connection with this Promotion
                  shall be resolved individually, without resort to any form of class action. This Promotion shall be
                  governed by, and construed in accordance with, the laws of the State of New York, regardless of
                  principles of conflicts of laws that may require the application of the laws of another jurisdiction.
                  Any action or litigation concerning this Promotion shall take place exclusively in the federal or
                  state courts sitting in New York, New York, and each entrant expressly consents to the jurisdiction of
                  and venue in such courts and waives all defenses of lack of jurisdiction and inconvenient forum with
                  respect to such courts. Each entrant agrees to service of process by mail or other method acceptable
                  under the laws of the State of New York. <br />
                  <strong>
                    ANY CLAIMS, JUDGMENTS AND/OR AWARDS SHALL BE LIMITED TO ACTUAL OUT-OF-POCKET COSTS ASSOCIATED WITH
                    ENTERING THIS PROMOTION. ENTRANT HEREBY WAIVES ANY RIGHTS OR CLAIMS TO ATTORNEY'S FEES AND TO
                    INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL AND CONSEQUENTIAL DAMAGES OF ENTRANT, WHETHER FORESEEABLE OR
                    NOT AND WHETHER BASED ON NEGLIGENCE OR OTHERWISE.
                  </strong>
                </li>
                <br />
                <li>
                  <strong>GENERAL: </strong>This Promotion is void outside the 50 United States and D.C. and where
                  prohibited by law and is subject to all applicable Federal, State and local laws and regulations. By
                  entering this Promotion, each Entrant agrees to be bound by these Official Rules and the decisions of
                  Sponsor. In addition, each Entrant represents and warrants that all information contained in his/her
                  entry is true and accurate. Sponsor is not responsible for any printing errors in these Official Rules
                  and Sponsor reserves the right to correct clerical or typographical errors in all promotional
                  materials. In the event this Promotion is compromised for any reason (including due to computer virus,
                  tampering, bugs, computer malfunction, fraud, unauthorized intervention or other causes beyond the
                  control of Sponsor) which, in the sole opinion of Sponsor, corrupts or impairs the administration,
                  security, fairness or proper play of this Promotion, Sponsor reserves the right in their sole
                  discretion to suspend, cancel, modify or terminate this Promotion, and to hold a random drawing from
                  among all eligible, non-suspect Entries received prior to such action. In the event of any such
                  suspension, cancellation, modification or termination a notice of such action will be posted on the
                  Website, if functioning. Sponsor is not responsible if the Promotion cannot be implemented or any
                  prize cannot be awarded due to delays or interruptions beyond Sponsor’s control, including those due
                  to acts of God, acts of war, strikes, governmental action, natural disasters, weather or acts of
                  terrorism. The use of automated software or computer programs to register for or enter this Promotion
                  is prohibited and any individual who uses or attempts to use such methods to register, to play or to
                  enter will be disqualified. Sponsor reserves the right, in their sole discretion, to disqualify any
                  entrant (and all of her/his entries) from this Promotion who does not comply with these Official
                  Rules, and/or from any other promotion conducted now or in the future by Sponsor or any of their
                  affiliated companies if such entrant tampers with the entry process or if her/his fraud or misconduct
                  affects the integrity of this Promotion or otherwise interferes with the proper conduct of this
                  Promotion.
                  <br />
                  <br />
                  By submitting a Photo/Entry, you represent and warrant that: (i) you are the sole and exclusive owner
                  of the Photo/Entry and all rights in and to the Photo; (ii) you have the full and exclusive right,
                  power and authority to submit the Photo/Entry to Sponsor upon the terms and conditions set forth
                  herein; (iii) no rights in the Photo/Entry have previously been granted to any person, firm,
                  corporation or other entity or otherwise exercised or exploited; (iv) the Photo/Entry has not been
                  submitted for any other contest(s) and it has not won any previous awards or Grand Prize; and (v) the
                  full use of the Photo/Entry or any part thereof will not in any way violate or infringe upon any
                  copyright (statutory or otherwise), constitute a libel or defamation of or an invasion of the rights
                  of privacy or publicity of, or otherwise violate or infringe upon any other personal, property or
                  other rights of any person, firm, corporation or other entity or subject Sponsor to any costs or
                  liability of any kind or nature whatsoever.
                  <br />
                  <br />
                  The Photo/Entry must not, in the sole discretion of Sponsor, contain any inappropriate content
                  including but not limited to, material which is (or promotes activities which are) abusive, harassing,
                  threatening, an invasion of a right of privacy of another person, bigoted, hateful, racially
                  offensive, discriminatory (based on race, sex, religion, national origin, physical disability, sexual
                  orientation or age), violent (e.g., relating to murder, the sales of weapons, cruelty, abuse, etc.),
                  vulgar, obscene, pornographic or otherwise sexually explicit, harmful or can reasonably be expected to
                  harm any person or entity, profane, offensive or otherwise objectionable as determined by Sponsor
                  and/or Administrator in their sole discretion; must not contain material which is (or promotes
                  activities which are) illegal or encourage or advocate illegal activity or the discussion of illegal
                  activities with the intent to commit them (including but not limited to Photo/Entry that are, or
                  represent an attempt to engage in, child pornography, stalking, sexual assault, fraud, trafficking in
                  obscene or stolen material, underage drinking, drug dealing and/or drug abuse, harassment, computer
                  hacking, theft, or conspiracy to commit any criminal activity) or otherwise unlawful. The Entry must
                  not violate these Rules or the TOU.
                  <br />
                  <br />
                  <strong>
                    {' '}
                    CAUTION: ANY ATTEMPT TO DELIBERATELY DAMAGE ANY WEBSITE OR UNDERMINE THE LEGITIMATE OPERATION OF
                    THIS PROMOTION IS A VIOLATION OF CRIMINAL AND CIVIL LAWS AND SPONSOR RESERVES THE RIGHT TO SEEK
                    DAMAGES FROM ANYONE MAKING SUCH AN ATTEMPT TO THE FULLEST EXTENT PERMITTED BY LAW.
                  </strong>
                </li>
                <br />
                <li>
                  {' '}
                  <strong>WINNERS’ LIST:</strong> For a winner’s list (available after <strong>April 1, 2022</strong>)
                  send a stamped, self-addressed envelope (postage not required for VT residents) to Hallmark Channel’s
                  Loveuary Sweepstakes Winner’s List, ATTN: LM, 494 Eighth Avenue, Suite 1700, New York, NY 10001.{' '}
                  <br />
                  <br />
                  Note: Trademarks and copyrights are the property of their owner and are not sponsors or affiliated
                  with this promotion.
                </li>
              </ol>
            </p>
          </Col>
        </Row>
      </MotionDiv>
    </Container>
  );
};
export default RulesPage;
