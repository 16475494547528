import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'infrastructure/redux/store';
import { GalleryItemsPerPage } from 'constants/index';
import { IGalleryItemDto, IGalleryPageDto } from 'types/Gallery';

export interface IGalleryState {
  page: number;
  totalCount: number;
  items: Array<IGalleryItemDto>;
}

const initialState: IGalleryState = {
  page: 1,
  totalCount: -1,
  items: [],
};

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    addPageItems: (state, action: PayloadAction<IGalleryPageDto>) => {
      state.items = [...state.items, ...action.payload.items];
      state.totalCount = action.payload.totalCount;
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    incrementLike: (state, action: PayloadAction<string>) => {
      state.items = state.items.map((item) => {
        if (item.id !== action.payload) {
          return item;
        }
        return { ...item, likeCount: item.likeCount + 1 };
      });
    },
    decrementLike: (state, action: PayloadAction<string>) => {
      state.items = state.items.map((item) => {
        if (item.id !== action.payload) {
          return item;
        }
        return { ...item, likeCount: item.likeCount - 1 };
      });
    },
  },
});

export const { addPageItems, changePage, incrementLike, decrementLike } = gallerySlice.actions;

export const selectItemCount = (state: RootState) => state.gallery.items.length;
export const selectItems = ({ gallery }: RootState) => {
  const start = (gallery.page - 1) * GalleryItemsPerPage;
  const end = start + GalleryItemsPerPage;
  return gallery.items.slice(start, end);
};
export const selectIsLastPage = (state: RootState) =>
  state.gallery.totalCount <= state.gallery.page * GalleryItemsPerPage;

export default gallerySlice.reducer;
