import { Button } from '../Layout';
import usePageView from '../../hooks/usePageView';
import { Col, Row, Container } from 'react-bootstrap';
import PageData from 'constants/PageViewTracking';
import { Link } from 'react-router-dom';
import { SignUpFormRoute, SuccessRoute, SignedInUploadRoute } from '../../constants/Routes';
import Prizes from '../Layout/Prizes';
import MapContainer from '../Layout/Map/MapContainer';
import LandingGallery from 'components/Layout/LandingGallery';
import { MotionH1 } from 'components/Shared/MotionDiv';
import animateInTransitionProps from 'infrastructure/utils/animateIn';
import LoveuaryLogoCol from 'components/Hallmark/LoveuaryLogoCol';
import { useAppSelector } from '../../hooks/useReduxHelper';
import { selectHeartAddedToday, selectIsSignedIn } from 'slices/User/UserSlice';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';

const heartCta = (heartAddedToday: boolean) => {
  return heartAddedToday ? (
    <p>
      <span>Come back tomorrow to add another heart!</span>
    </p>
  ) : (
    <Link to={SignedInUploadRoute}>
      <Button
        withArrow
        className="w-100 mb-3 mb-sm-0"
        onClick={() => {
          trackEventData(EventTrackingData.AddAnotherHeartButtonClick);
        }}
      >
        Add Another Heart
      </Button>
    </Link>
  );
};

const LandingPage: React.FC = () => {
  usePageView(PageData.LandingPageData);
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const heartAddedToday = useAppSelector(selectHeartAddedToday);

  return (
    <>
      <Container className="landing_main">
        <Row>
          <LoveuaryLogoCol />
          <Col lg={{ span: 12 }}>
            <MotionH1 {...animateInTransitionProps}>
              Show us your heart hands for a chance to win a personal assistant for a month!
            </MotionH1>
          </Col>
        </Row>
        <Prizes />
        {!isSignedIn && (
          <Row className="entry-row enter-now-cta">
            <Col sm={7}>
              <p>
                <span className="bold">Join Hallmark fans all over the country!</span> Share your hands for the chance
                to win our Grand Prize.
              </p>
            </Col>
            <Col sm={5}>
              <Link to={SignUpFormRoute}>
                <Button
                  withArrow
                  className="w-100"
                  onClick={() => {
                    trackEventData(EventTrackingData.EnterNowButtonClick);
                  }}
                >
                  Enter Now
                </Button>
              </Link>
            </Col>
          </Row>
        )}
        <MapContainer />
        <Row className="entry-row">
          {isSignedIn ? (
            <>
              <Col sm={{ span: 5, offset: 1 }}>{heartCta(heartAddedToday)}</Col>
              <Col sm={5}>
                <Link to={SuccessRoute}>
                  <Button
                    withArrow
                    className="w-100"
                    onClick={() => {
                      trackEventData(EventTrackingData.EarnBonusEntriesButtonClick);
                    }}
                  >
                    Earn Bonus Entries
                  </Button>
                </Link>
              </Col>
            </>
          ) : (
            <Col sm={{ span: 6, offset: 3 }}>
              <Link to={SignUpFormRoute}>
                <Button
                  withArrow
                  className="w-100"
                  onClick={() => {
                    trackEventData(EventTrackingData.EnterNowButtonClick);
                  }}
                >
                  Enter Now
                </Button>
              </Link>
            </Col>
          )}
        </Row>
        <LandingGallery />
      </Container>
    </>
  );
};

export default LandingPage;
