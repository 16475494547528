/* eslint-disable react-hooks/exhaustive-deps */
import trackPageViewGoogleAndOmniture from '../infrastructure/tracking';

import { useEffect } from 'react';
import { PageData } from '../constants/PageViewTracking';

// const usePageView: (pageTitle: string, pagePath: string) => void = (pageTitle, pagePath) => {
//   useEffect(() => {
//     trackPageViewGoogleAndOmniture(pageTitle, pagePath);
//   }, []);
// };

const usePageView: (pageData: PageData) => void = ({ title, path }) => {
  useEffect(() => {
    trackPageViewGoogleAndOmniture(title, path);
  }, []);
};

export default usePageView;
