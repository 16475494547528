import React from 'react';
import { Button, Loader } from '../Layout';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GalleryRoute } from '../../constants/Routes';
import { iconMarker } from '../../images/icons';
import { IGalleryItemDto } from 'types';
import { PlaceholderPromotedItems } from '../../constants/index';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';

interface IPromotedGalleryProps {
  items: Array<IGalleryItemDto> | null;
}

const PromotedItem: React.FC<{ username: string; thumbnailUrl: string; state: string }> = ({
  username,
  thumbnailUrl,
  state,
}) => (
  <Col xs={4}>
    <div className="gallery-item gallery-image">
      <div className="background-img-group">
        <img src={thumbnailUrl} alt="gallery-item" className="img-fluid" />
        <img src={thumbnailUrl} alt="gallery-item" className="img-fluid backer" />
      </div>
    </div>
  </Col>
);

const PromotedGallery: React.FC<IPromotedGalleryProps> = ({ items }) => {
  const promotedItems = items !== null && items.length < 3 ? PlaceholderPromotedItems : items;
  return (
    <>
      <Row className="row-tight">
        <Col sm={12}>
          <span className="subheader">Heart your favorite to earn more bonus entries</span>
        </Col>
        {promotedItems === null ? (
          <Loader />
        ) : (
          promotedItems.map((x) => <PromotedItem {...x} key={x.thumbnailUrl || x.originalUrl} />)
        )}
      </Row>
      <Link
        to={GalleryRoute}
        className="gallery-button"
        onClick={() => trackEventData(EventTrackingData.ViewGalleryClickSuccessPage)}
      >
        <Button>View Gallery</Button>
      </Link>
    </>
  );
};

export default PromotedGallery;
