import { getPresignedUrlUrl } from 'constants/ApiUrls';
import CallApi from '../infrastructure/api/CallApi';

interface IPresignedUrlResponse {
  presignedUrl: string;
  fileName: string;
  assetUrl: string;
}

const getPresignedUrl = async (filename: string) => {
  const apiUrl = getPresignedUrlUrl(filename);
  const { data } = await CallApi<IPresignedUrlResponse>(apiUrl, 'GET');
  return data;
};

export default getPresignedUrl;
