import React, { useState } from 'react';
import { Button } from '../Layout';
import { Col, Row } from 'react-bootstrap';
import { IWinPremiereDto, IWinPremiereEntryModel } from '../../types/WatchAndWin';
import { useAppDispatch } from '../../hooks/useReduxHelper';
import { Formik, Field, Form } from 'formik';
import CallApi from 'infrastructure/api/CallApi';
import { IUserDto } from '../../types/User';
import { enterWinPremiereUrl } from '../../constants/ApiUrls';
import { updateUser } from 'slices/User/UserSlice';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';
import { onExceptionTestForClosed } from '../../helpers/index';

interface IWatchAndWinFormProps {
  premiere: IWinPremiereDto | null;
  getAnswerState: (winPremiereId: string) => 'Correct' | 'Incorrect' | 'Unanswered';
  userId: string;
  showWinPremierePlaceholder: boolean;
  isFirst: boolean;
}

const WatchAndWinForm: React.FunctionComponent<IWatchAndWinFormProps> = ({
  premiere,
  getAnswerState,
  userId,
  showWinPremierePlaceholder,
  isFirst,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const numberShown = isFirst ? '1' : '2';

  if (showWinPremierePlaceholder) {
    return (
      <Col xs={12}>
        <div className="section-number">
          <span className="value">{numberShown}</span>
        </div>
        <section className="form-content watch-and-win">
          <Row>
            <Col>
              <span className="subheader">
                <div className="">
                  Watch the all new Loveuary premieres and count the hearts, starting with the premiere of Butlers in
                  Love on <br className="d-none d-lg-inline" />
                  SAT JAN 29 8/7c.
                </div>
              </span>
            </Col>
          </Row>
        </section>
      </Col>
    );
  }

  if (!premiere) {
    return null;
  }

  const answerState = getAnswerState(premiere.id);
  const incorrectMessage = `Sorry that's not correct! Tune-in to ${premiere.nextTitle} airing at ${premiere.nextPremiereDate} for more chances to win.`;
  const incorrectGenericMessage = `Sorry that's not correct! Tune-in to this week's premiere for more chances to win.`;

  if (answerState === 'Correct') {
    trackEventData(EventTrackingData.HeartNumberGuessCorrect);
    return (
      <Col xs={12}>
        <div className="section-number">
          <span className="value">{numberShown}</span>
        </div>
        <span className="subheader short">Watch & Win</span>
        <section className="form-content watch-and-win">
          <Row className="">
            <Col>
              <p>
                Congratulations! You earned 1 entry by correctly entering the number of hearts that appeared on screen
                during <strong>{premiere.title}</strong>.
              </p>
            </Col>
          </Row>
        </section>
      </Col>
    );
  }

  if (answerState === 'Incorrect') {
    trackEventData(EventTrackingData.HeartNumberGuessIncorrect);
    return (
      <Col xs={12}>
        <div className="section-number">
          <span className="value">{numberShown}</span>
        </div>
        <span className="subheader short">Watch & Win</span>
        <section className="form-content watch-and-win">
          <Row className="">
            <Col>
              <p>{premiere.nextTitle ? incorrectMessage : incorrectGenericMessage}</p>
            </Col>
          </Row>
        </section>
      </Col>
    );
  }

  const initialValues: { heartsNumber?: number } = {
    heartsNumber: undefined,
  };

  return (
    <Col xs={12}>
      <div className="section-number">
        <span className="value">{numberShown}</span>
      </div>
      <span className="subheader short">Watch & Win</span>
      <section className="form-content watch-and-win">
        <Row className="">
          <Col>
            <p>{premiere.preSubmissionDescription}</p>
          </Col>
        </Row>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            if (values.heartsNumber !== undefined) {
              setIsSubmitting(true);
              const postForm: IWinPremiereEntryModel = {
                numberOfHearts: values.heartsNumber,
                entityId: premiere.id,
                userId,
              };
              CallApi<IUserDto>(enterWinPremiereUrl, 'POST', postForm)
                .then(({ data }) => {
                  dispatch(updateUser(data));
                  trackEventData(EventTrackingData.HeartNumberGuessSuccess);
                })
                .catch((error) => {
                  trackEventData(EventTrackingData.HeartNumberGuessError);
                  setIsSubmitting(false);
                  onExceptionTestForClosed(error);
                });
            }
          }}
        >
          <Form className="heart-input">
            <Field
              className="input-hearts"
              id="heartsNumber"
              name="heartsNumber"
              placeholder="Enter Number..."
              type="number"
              min="0"
            />

            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              onClick={() => trackEventData(EventTrackingData.HeartNumberGuessConfirmClick)}
            >
              Confirm
            </Button>
          </Form>
        </Formik>
      </section>
    </Col>
  );
};

export default WatchAndWinForm;
