import { signUpUrl } from 'constants/ApiUrls';
import { Field, Form, Formik } from 'formik';
import MotionDiv from 'components/Shared/MotionDiv';
import { SignUpFormValidationSchema } from 'helpers/FormValidationSchema';
import { useAppDispatch } from 'hooks/useReduxHelper';
import CallApi from 'infrastructure/api/CallApi';
import useLinkTarget from 'infrastructure/isWebView/useLinkTarget';
import fadeTransitionProps from 'infrastructure/utils/transitions';
import React, { useState } from 'react';
import { Col, Row, Image, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FormFeedback } from 'reactstrap';
import { signup } from 'slices/User/UserSlice';
import Swal from 'sweetalert2';
import { Button } from '../Layout';
import TextField from '../Form/TextField';
//@ts-ignore
import MaskedInput from '@biproxi/react-text-mask';
import zipCodeMask from '../Form/ZipCodeMask';
import { SuccessRoute } from '../../constants/Routes';
import { StackedLogo } from 'images/logos';
import { usePageView } from 'hooks';
import PageData from 'constants/PageViewTracking';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';
import DropzoneField from '../Form/DropzoneField';
import { ISignUpModel, IUserDto } from 'types';
import ProviderList from '../Form/ProviderList';
import LoveuaryLogoCol from 'components/Hallmark/LoveuaryLogoCol';

const SignUpPage: React.FC = () => {
  usePageView(PageData.SignUpFormPageData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const linkTarget = useLinkTarget();
  const dispatch = useAppDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onSubmit = (values: ISignUpModel) => {
    setIsSubmitting(true);

    CallApi<IUserDto>(signUpUrl, 'POST', values)
      .then(({ data }) => {
        onSignUpSuccess(data);
      })
      .catch(({ response }) => {
        handleError(response);
        setIsSubmitting(false);
      });
  };

  const onSignUpSuccess = (user: IUserDto) => {
    trackEventData(EventTrackingData.SignUpFormSuccess);
    if (!user.entryAdded) {
      Swal.fire({
        title: 'Oops!',
        text: 'You have already submitted a photo today',
        backdrop: false,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
    dispatch(signup(user));
    navigate(SuccessRoute);
  };

  const handleError = (response: any) => {
    trackEventData(EventTrackingData.SignUpFormError);
    let alertDescription = 'Something went wrong! Please try again.';
    if (response && response.data && response.data.error && response.data.error.code === 'CustomError') {
      alertDescription = response.data.error.message;
    }
    Swal.fire({
      title: 'Oops!',
      text: alertDescription,
      backdrop: false,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const initialValues: ISignUpModel = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    zipCode: '',
    provider: '',
    crownMediaOptIn: true,
    ageConfirmation: false,
    agreeToRules: false,
    originalUrl: '',
    thumbnailUrl: null,
  };

  return (
    <React.Fragment>
      <Container className="form-container header">
        <Row>
          <Col>
            <div className="mobile-header-signup">
              <span className="padding"></span>
              <LoveuaryLogoCol />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="section-header">
          <Col>
            <h1>
              Show your love for the chance to win
              <br /> our romantic giveaway!
            </h1>
            <p>
              <span className="bold">Join Hallmark fans all over the country!</span> Make a heart with your loved one’s
              hand for the chance to win our grand prize.
            </p>
          </Col>
        </Row>
      </Container>
      {/* @ts-ignore */}
      <Container>
        <section className="form-content">
          <MotionDiv className="container form-container" {...fadeTransitionProps}>
            <Formik initialValues={initialValues} validationSchema={SignUpFormValidationSchema} onSubmit={onSubmit}>
              {({ values, errors, touched }) => (
                <Form id="EntryForm" noValidate>
                  <Row>
                    <Col xs={12} sm={{ span: 6, offset: 0 }}>
                      <TextField
                        name="firstName"
                        label="First Name*"
                        id="firstName"
                        type="text"
                        maxLength={20}
                        placeholder="First Name"
                      />
                    </Col>
                    <Col xs={12} sm={{ span: 6, offset: 0 }}>
                      <TextField
                        name="lastName"
                        label="Last Name*"
                        id="lastName"
                        type="text"
                        maxLength={20}
                        placeholder="Last Name"
                      />
                    </Col>
                    <Col xs={12} sm={{ span: 6, offset: 0 }}>
                      <TextField
                        name="emailAddress"
                        label="Email Address*"
                        id="emailAddress"
                        type="email"
                        maxLength={100}
                        placeholder="Email Address"
                      />
                    </Col>
                    <Col xs={12} sm={{ span: 6, offset: 0 }}>
                      <TextField
                        name="zipCode"
                        label="Zip Code*"
                        id="zipCode"
                        type="zipCode"
                        placeholder="Zip Code"
                        maxLength={5}
                        mask={zipCodeMask}
                        tag={MaskedInput}
                        guide={false}
                        inputMode="numeric"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <TextField name="provider" label="Provider" id="lastName" type="select" maxLength={20}>
                        <option disabled hidden defaultValue="" value="">
                          Select provider...
                        </option>
                        {ProviderList.map((provider: string) => (
                          <option value={provider} key={provider}>
                            {provider}
                          </option>
                        ))}
                      </TextField>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="dropzone-container">
                      <DropzoneField name="originalUrl" isUploading={isUploading} setIsUploading={setIsUploading} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={{ span: 12, offset: 0 }}>
                      <label htmlFor="agreeToRules" className="checkbox-container">
                        <Field name="agreeToRules" type="checkbox" id="agreeToRules" checked={values.agreeToRules} />
                        <span className="checkmark" />
                        <p>
                          I have read and agree to the{' '}
                          <a
                            href="/rules"
                            target={linkTarget}
                            rel="noopener noreferrer"
                            onClick={() => trackEventData(EventTrackingData.OfficialRulesClick)}
                          >
                            Official Rules
                          </a>
                          &nbsp;and&nbsp;
                          <a
                            href="https://www.hallmarkchannel.com/crown-media-family-networks-terms-of-use"
                            target={linkTarget}
                            rel="noopener noreferrer"
                            onClick={() => trackEventData(EventTrackingData.TermsOfUseClick)}
                          >
                            Terms of Use
                          </a>
                          .*
                        </p>
                        {touched.agreeToRules && (
                          <FormFeedback tooltip valid={!errors.agreeToRules}>
                            {errors.agreeToRules}
                          </FormFeedback>
                        )}
                      </label>

                      <label htmlFor="ageConfirmation" className="checkbox-container">
                        <Field
                          name="ageConfirmation"
                          type="checkbox"
                          id="ageConfirmation"
                          checked={values.ageConfirmation}
                        />
                        <span className="checkmark" />
                        <p>I am 21 years or older.*</p>
                        {touched.ageConfirmation && (
                          <FormFeedback tooltip valid={!errors.ageConfirmation}>
                            {errors.ageConfirmation}
                          </FormFeedback>
                        )}
                      </label>

                      <label htmlFor="crownMediaOptIn" className="checkbox-container">
                        <Field
                          name="crownMediaOptIn"
                          type="checkbox"
                          id="crownMediaOptIn"
                          checked={values.crownMediaOptIn}
                        />
                        <span className="checkmark" />
                        <p>
                          By checking this box I acknowledge that Crown Media and its affiliated companies can email me
                          about special offers and promotions, and that I agree to Hallmark Channel's{' '}
                          <a
                            href="https://www.hallmarkchannel.com/privacy-policy"
                            target={linkTarget}
                            rel="noopener noreferrer"
                            onClick={() => trackEventData(EventTrackingData.PrivacyPolicyClick)}
                          >
                            Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://www.hallmarkchannel.com/crown-media-family-networks-terms-of-use"
                            target={linkTarget}
                            rel="noopener noreferrer"
                            onClick={() => trackEventData(EventTrackingData.TermsOfUseClick)}
                          >
                            Terms of Use
                          </a>
                          .
                        </p>
                      </label>
                    </Col>
                  </Row>

                  <Button
                    type="submit"
                    variant="primary"
                    withArrow
                    isLoading={isSubmitting}
                    disabled={isSubmitting || isUploading}
                    onClick={() => trackEventData(EventTrackingData.ConfirmButtonClickSignUpPage)}
                  >
                    Confirm
                  </Button>
                </Form>
              )}
            </Formik>
          </MotionDiv>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default SignUpPage;
