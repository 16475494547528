import { createContext, useContext, useRef } from 'react';
const minTimeBetweenAdsMs = 8000;

const hasMinTimeElapsed = (previousTime, newTime) => {
  if (!previousTime) {
    return true;
  }
  return newTime - previousTime > minTimeBetweenAdsMs;
};
const createAdManager = () => {
  const { networkId, serverUrl, profileId, siteSectionId } = window.config;
  let adManager;
  try {
    window.tv.freewheel.SDK.setLogLevel(window.tv.freewheel.SDK.LOG_LEVEL_QUIET);
    adManager = new window.tv.freewheel.SDK.AdManager();
    adManager.setNetwork(networkId);
    adManager.setServer(serverUrl);
    adManager._context.setProfile(profileId);
    adManager._context.setSiteSection(siteSectionId);
  } catch {}
  return adManager;
};

const FreewheelContext = createContext();

export const FreewheelProvider = ({ children }) => {
  const urlRef = useRef();
  const lastRequestTimeRef = useRef();

  const adManager = createAdManager();
  const { enableFreewheel } = window.config;

  function submitRequest(url) {
    const now = Date.now();
    if (enableFreewheel && adManager && urlRef.current !== url) {
      urlRef.current = url;
      if (hasMinTimeElapsed(lastRequestTimeRef.current, now)) {
        lastRequestTimeRef.current = now;
        // this is to ensure we send request after react re-render
        setTimeout(() => {
          adManager.newContextWithContext(adManager._context);
          adManager._context.submitRequest();
          console.log('submit FW request');
        }, 10);
      }
    }
  }
  const context = {
    submitRequest,
  };
  return <FreewheelContext.Provider value={context}>{children}</FreewheelContext.Provider>;
};

export const useFreewheelContext = () => useContext(FreewheelContext);
