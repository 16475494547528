import stateLocations from '../../../constants/stateLocations';
import { ImagePinProps } from 'types';
import MotionDiv from 'components/Shared/MotionDiv';


const MapPin: React.FC<ImagePinProps> = ({ state, imageUrl }) => {
  return (
    <MotionDiv
      key={imageUrl}
      initial={{  scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{  scale: 0, opacity: 0 }}
      style={{
        left: `calc(${stateLocations[state][0]}% - 8%)`,
        top: `calc(${stateLocations[state][1]}% - 13%)`,
        zIndex: `1000`,
      }}
      className="map-item"
    >
      <div className="map-imagewrapper">

        <img src={imageUrl} alt="" className="map-image-backer" />
        <img src={imageUrl} alt={`heart from ${state}`} className="map-image" />
      </div>
    </MotionDiv>
  );
};

export default MapPin;
