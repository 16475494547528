import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import smoothscroll from 'smoothscroll-polyfill';
import { Provider } from 'react-redux';
import { BreakpointsProvider } from 'react-with-breakpoints';
import ErrorBoundary from './infrastructure/errors/ErrorBoundary';
import { store } from './infrastructure/redux/store';
import { FreewheelProvider } from 'infrastructure/freewheel/provider';

smoothscroll.polyfill();

const breakpoints = {
  small: 576,
  medium: 768,
  large: 992,
  xlarge: Infinity,
};

ReactDOM.render(
  <ErrorBoundary>
    <BreakpointsProvider breakpoints={breakpoints}>
      <Provider store={store}>
        <FreewheelProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
        </FreewheelProvider>
      </Provider>
    </BreakpointsProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
