import FacebookIcon from "./FacebookIcon";
import InstagramIcon from "./InstagramIcon";
import PinterestIcon from "./PinterestIcon";
import TwitterIcon from "./TwitterIcon";
import YoutubeIcon from "./YoutubeIcon";

const SocialIcons = {
  Facebook: FacebookIcon,
  Instagram: InstagramIcon,
  Pinterest: PinterestIcon,
  Twitter: TwitterIcon,
  Youtube: YoutubeIcon,
};

export default SocialIcons;
