import GetConfig, { config } from '../../helpers/GetConfig';
import { EventData } from '../../constants/EventTracking';
import { consoleLogEventData } from './TrackingConsoleLogs';

interface EventProperties {
  event_category: string;
  send_to: string;
  event_label?: string;
}

let trackedInitialComScorePageView = false;

const sendToComScore: (config: config) => void = ({ enableComScore }) => {
  if (enableComScore && window.COMSCORE) {
    window.COMSCORE.beacon({ c1: '2', c2: '8272010' });
    fetch('/pageview_candidate.txt');
  }
};

export const trackEventData: (trackingData: EventData) => void = ({ category, action, label }) =>
  trackEvent(category, action, label);
export const trackEvent: (category: string, action: string, label?: string) => void = (category, action, label) => {
  const { enableGoogleAnalytics, googleAnalyticsWebPropertyId } = GetConfig();
  consoleLogEventData(category, action, label);
  if (window.gtag && enableGoogleAnalytics) {
    const properties: EventProperties = {
      event_category: category,
      send_to: googleAnalyticsWebPropertyId,
    };
    if (label) {
      properties['event_label'] = label;
    }
    window.gtag('event', action, properties);
  }
};

export const trackPageView: (pageTitle: string, pagePath: string) => void = (pageTitle, pagePath) => {
  const config = GetConfig();
  const gaEnabled = config.enableGoogleAnalytics;
  const fullPageTitle = `Hallmark Channel's Loveuary Sweepstakes - ${pageTitle}`;

  if (trackedInitialComScorePageView) {
    sendToComScore(config);
  }
  trackedInitialComScorePageView = true;
  if (window.gtag && gaEnabled) {
    const properties = {
      page_title: fullPageTitle,
      page_path: pagePath,
    };
    window.gtag('config', config.googleAnalyticsWebPropertyId, properties);
    const crossDomainProperties = {
      page_title: fullPageTitle,
      page_path: `/loveuary/sweepstakes${pagePath}`,
    };
    window.gtag('config', config.crossDomainGoogleAnalyticsWebPropertyId, crossDomainProperties);
  }

  // console.log('page_title: ', fullPageTitle);
  // console.log('page_path: ', pagePath);
};
