import { uploadImageUrl } from 'constants/ApiUrls';
import { Form, Formik } from 'formik';
import MotionDiv from 'components/Shared/MotionDiv';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHelper';
import callApi from 'infrastructure/api/CallApi';
import useLinkTarget from 'infrastructure/isWebView/useLinkTarget';
import fadeTransitionProps from 'infrastructure/utils/transitions';
import React, { useState } from 'react';
import { Col, Row, Image, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { selectHeartAddedToday, selectUserId, signup, updateUser } from 'slices/User/UserSlice';
import Swal from 'sweetalert2';
import { Button } from '../Layout';
import { LandingRoute, SuccessRoute } from '../../constants/Routes';
import { StackedLogo, GameSponsorLogo } from 'images/logos';
import { usePageView } from 'hooks';
import PageData from 'constants/PageViewTracking';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';
import DropzoneField from '../Form/DropzoneField';
import { IUserDto } from 'types';
import { Navigate } from 'react-router-dom';

const SignedInUploadPage: React.FC = () => {
  usePageView(PageData.SignUpFormPageData);

  const heartAddedToday = useAppSelector(selectHeartAddedToday);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const userId = useAppSelector(selectUserId);

  const onSubmit = (values: object) => {
    setIsSubmitting(true);

    callApi<IUserDto>(uploadImageUrl, 'POST', { userId, ...values })
      .then(({ data }) => {
        onUploadSuccess(data);
      })
      .catch(({ response }) => {
        handleError(response);
        setIsSubmitting(false);
        trackEventData(EventTrackingData.UploadFormConfirmError);
      });
  };


  const onUploadSuccess = (user: IUserDto) => {
    trackEventData(EventTrackingData.UploadFormConfirmSuccess);
    if (!user.entryAdded) {
      Swal.fire({
        title: 'Oops!',
        text: 'You have already submitted a photo today',
        backdrop: false,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    dispatch(updateUser(user));
    navigate(SuccessRoute);
  };

  const handleError = (response: any) => {
    let alertDescription = 'Something went wrong! Please try again.';
    if (response && response.data && response.data.error && response.data.error.code === 'CustomError') {
      alertDescription = response.data.error.message;
    }
    Swal.fire({
      title: 'Oops!',
      text: alertDescription,
      backdrop: false,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    trackEventData(EventTrackingData.SignUpFormError);
  };

  if (heartAddedToday) {
    return <Navigate to={LandingRoute} />;
  }

  return (
    <React.Fragment>
      <Container className="form-container header">
        <Row>
          <Col>
            <div className="mobile-header-signup">
              <span className="padding"></span>
              <Image src={StackedLogo} alt="logo" className="img-fluid ctc-logo"></Image>

            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="section-header">
          <Col>
            <h1>
              Show your love for the chance to win
              <br /> our romantic giveaway!
            </h1>
            <p>
              <span className="bold">Join Hallmark fans all over the country!</span> Make a heart with your loved one’s
              hand for the chance to win our grand prize.
            </p>
          </Col>
        </Row>
      </Container>
      {/* @ts-ignore */}
      <section className="form-content">

        <MotionDiv className="container form-container" {...fadeTransitionProps}>
          <Formik
            initialValues={{
              originalUrl: '',
              thumbnailUrl: '',
            }}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched }) => (
              <Form id="EntryForm" noValidate>
                <Row>
                  <Col xs={12}>
                    <DropzoneField name="originalUrl" isUploading={isUploading} setIsUploading={setIsUploading} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={{ span: 12, offset: 0 }}></Col>
                </Row>
                <Button
                  type="submit"
                  variant="primary"
                  withArrow
                  isLoading={isSubmitting}
                  disabled={isSubmitting || isUploading}
                  onClick={() => trackEventData(EventTrackingData.UploadFormConfirmButtonClick)}
                >
                  Confirm
                </Button>
              </Form>
            )}
          </Formik>
        </MotionDiv>

      </section>
    </React.Fragment>
  );
};

export default SignedInUploadPage;
