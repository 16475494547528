import { useAppDispatch } from '../../../hooks/useReduxHelper';
import { Link } from 'react-router-dom';
import { LandingRoute } from '../../../constants/Routes';
import { iconUser } from 'images/icons';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';
import { useNavigate } from 'react-router';
import { logout } from 'slices/User/UserSlice';

interface INavBarLogoutLinkProps {
  username: string;
}

const NavBarLogoutLink: React.FC<INavBarLogoutLinkProps> = ({ username }) => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const onLogoutClick = () => {
    trackEventData(EventTrackingData.LogoutButtonClick);
    dispatch(logout());
    history(LandingRoute);
  };

  return (
    <div className="nav-bar_logout">
      <span className="username">
        <img src={iconUser} alt="icon-user" className="img-fluid icon" /> {username}
      </span>
      <button onClick={onLogoutClick}>Not you?</button>
    </div>
  );
};

export default NavBarLogoutLink;
