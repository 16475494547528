import { Route, Routes, useLocation } from 'react-router-dom';
import { useScrollToTop } from '../../hooks';
import ClosedLandingPage from '../../components/Pages/ClosedLandingPage';
import NotFoundPage from './NotFound404';
import * as Paths from 'constants/Routes';
import CloseWindow from './CloseWindow';
import OptionalAnimatePresence from 'components/Shared/OptionalAnimatePresence';
import RulesPage from 'components/Pages/RulesPage';

const ClosedRoutes = () => {
  const location = useLocation();
  useScrollToTop(location.pathname);
  return (
    // @ts-ignore
    <OptionalAnimatePresence>
      <Routes location={location}>
        {/* Navigation Routes */}
        <Route path={Paths.LandingRoute} element={<ClosedLandingPage />} key="landing" />
        <Route path={Paths.RulesRoute} element={<RulesPage />} key="rules" />
        <Route path="/closewindow" element={<CloseWindow />} key="closewindow" />

        {/* Error Routes */}
        <Route path="*" element={<NotFoundPage />} key="notfound" />
      </Routes>
    </OptionalAnimatePresence>
  );
};

export default ClosedRoutes;
