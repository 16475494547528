import { Button } from '../Layout';
import usePageView from '../../hooks/usePageView';
import { Col, Row, Container } from 'react-bootstrap';
import PageData from 'constants/PageViewTracking';
import { Link } from 'react-router-dom';
import { SignUpFormRoute, SuccessRoute, SignedInUploadRoute } from '../../constants/Routes';
import Prizes from '../Layout/Prizes';
import MapContainer from '../Layout/Map/MapContainer';
import LandingGallery from 'components/Layout/LandingGallery';
import { MotionH1 } from 'components/Shared/MotionDiv';
import animateInTransitionProps from 'infrastructure/utils/animateIn';
import LoveuaryLogoCol from 'components/Hallmark/LoveuaryLogoCol';
import { useAppSelector } from '../../hooks/useReduxHelper';
import { selectHeartAddedToday, selectIsSignedIn } from 'slices/User/UserSlice';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from 'constants/EventTracking';
import { MotionImage } from '../Shared/MotionDiv';
import hearthands from '../../images/landing/hearthands-mosaic.jpg';

const heartCta = (heartAddedToday: boolean) => {
  return heartAddedToday ? (
    <p>
      <span>Come back tomorrow to add another heart!</span>
    </p>
  ) : (
    <Link to={SignedInUploadRoute}>
      <Button
        withArrow
        className="w-100 mb-3 mb-sm-0"
        onClick={() => {
          trackEventData(EventTrackingData.AddAnotherHeartButtonClick);
        }}
      >
        Add Another Heart
      </Button>
    </Link>
  );
};

const ClosedLandingPage: React.FC = () => {
  usePageView(PageData.LandingPageData);
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const heartAddedToday = useAppSelector(selectHeartAddedToday);

  return (
    <>
      <Container className="landing_main">
        <Row>
          <LoveuaryLogoCol />
          <Col lg={{ span: 12 }}>
            <h2 className='landing-header'>Thanks for sharing the love with Hallmark!</h2>
            <h4 className='landing-subheader'>The contest entry period has closed.</h4>
          </Col>
          <Col className="landing-hearthands-container" lg={{ span: 12 }}>
            <MotionImage className="landing-hearthands" src={hearthands} {...animateInTransitionProps}></MotionImage>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClosedLandingPage;
