import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'infrastructure/redux/store';
import { IPrizeDto, IPrizeItemDto } from '../../types/Prizes';

export interface IPrizeState {
  week: number;
  prizeTitle: string;
  prizes: IPrizeItemDto[];
  prizeImageUrl: string;
}

const initialState: IPrizeState = {
  week: 0,
  prizeTitle: '',
  prizes: [],
  prizeImageUrl: '',
};

export const prizeSlice = createSlice({
  name: 'prize',
  initialState,
  reducers: {
    addCurrentPrize: (state, action: PayloadAction<IPrizeDto>) => {
      state.week = action.payload.week;
      state.prizeTitle = action.payload.prizeTitle;
      state.prizes = action.payload.prizes;
      state.prizeImageUrl = action.payload.prizeImageUrl;
    },
  },
});

export const { addCurrentPrize } = prizeSlice.actions;

export const selectPrize = ({ prize }: RootState) => prize;
export const selectPrizeWeek = ({ prize }: RootState) => prize.week;
export const selectPrizeTitle = ({ prize }: RootState) => prize.prizeTitle;
export const selectPrizes = ({ prize }: RootState) => prize.prizes;

export default prizeSlice.reducer;
