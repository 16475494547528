import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import PrizeModal from 'components/Pages/PrizeModal';
import { useAppSelector } from 'hooks/useReduxHelper';
import { selectPrize } from 'slices/Prizes/PrizeSlice';
import MotionDiv from 'components/Shared/MotionDiv';
import EventTrackingData from 'constants/EventTracking';
import { trackEventData } from 'infrastructure/tracking/GoogleAnalytics';

const Prizes: React.FC = () => {
  const [showModal, setShow] = useState(false);
  const prize = useAppSelector(selectPrize);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    trackEventData(EventTrackingData.ViewPrizesClick);
  };

  return (
    <Row className="prize-row">
      <Col xs={6} sm={{ span: 5, offset: 1 }} md={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
        <MotionDiv initial={{ y: '30%', opacity: 0 }} animate={{ y: '0', opacity: 1 }} transition={{ duration: 0.5 }}>
        <div className="card-prize">
          <img src="https://1240369105.rsc.cdn77.org/loveuary/prizes/grand-prize.jpg" alt="prize-img" className="w-100 img-fluid card-img" />
          <div className="card-heading">Grand Prize</div>
          <div className="card-info">Personal Assistant valued at $10,000</div>
        </div>
           </MotionDiv>
      </Col>

      {prize.week > 0 && (
        <Col xs={6} sm={5} md={5} lg={4}>
          <MotionDiv initial={{ y: '30%', opacity: 0 }} animate={{ y: '0', opacity: 1 }} transition={{ duration: 0.5 }}>
          <div className="card-prize">
            <img src={prize.prizeImageUrl} alt="prize-img" className="w-100 img-fluid card-img" />
            <div className="card-heading">Week {prize.week} Prize</div>
            <div className="card-info">{prize.prizeTitle}</div>

            <PrizeModal prizes={prize.prizes} prizeWeek={prize.week} show={showModal} onHide={handleClose} mainPrizeImage={prize.prizeImageUrl} />
            <Button variant="modal" onClick={handleShow}>
              View prizes
            </Button>
          </div>
          </MotionDiv>
        </Col>
      )}
    </Row>
  );
};

export default Prizes;
