import { useEffect, useState } from 'react';

export const loadSingleImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(resolve, 5000);
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      clearTimeout(timeout);
      resolve('');
    };
    img.onerror = () => {
      clearTimeout(timeout);
      reject('');
    };
    img.src = src;
    img.style.display = 'none';
    document.body.appendChild(img);
  });
};

const loadingTimeLimit = 5000;

const useImages: (images: Array<string>) => boolean = (images) => {
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

  useEffect(() => {
    const imageLoadingPromises = images.map(loadSingleImage);
    Promise.all(imageLoadingPromises).finally(() => {
      setImagesLoaded(true);
    });
    setTimeout(() => {
      setImagesLoaded(true);
    }, loadingTimeLimit);
  }, [images]);

  return imagesLoaded;
};

export default useImages;
