import CallApi from 'infrastructure/api/CallApi';
import { Button } from 'react-bootstrap';
// import { likeItem, unlikeItem } from 'slices/LikedItems/LikedItemSlice';
import { useAppSelector, useAppDispatch } from '../../hooks/useReduxHelper';
// import { selectUserId } from '../../slices/LikedItems/LikedItemSlice';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { trackEventData } from '../../infrastructure/tracking/GoogleAnalytics';
import EventTrackingData from '../../constants/EventTracking';
import { getHeartItemUrl } from 'constants/ApiUrls';
import { selectIsSignedIn, selectUserId, updateUser } from 'slices/User/UserSlice';
import { IUserDto } from 'types';
import { selectIsLiked } from '../../slices/User/UserSlice';
import { decrementLike, incrementLike } from 'slices/Gallery/GallerySlice';
import SignInToLikeModal from '../Pages/SignInToLikeModal';
import { onExceptionTestForClosed } from '../../helpers/index';
interface ILikeButtonProps {
  id: string;
  likeCount: number;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IHeartItemModel {
  userId: string;
  itemId: string;
}

const LikeButton: React.FC<ILikeButtonProps> = ({ id, likeCount, setShowModal }) => {
  const userId = useAppSelector(selectUserId);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isLiked = useAppSelector(selectIsLiked)(id);
  const isSignedIn = useAppSelector(selectIsSignedIn);

  const dispatch = useAppDispatch();

  const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    trackEventData(EventTrackingData.HeartButtonClick);
    if (!isSignedIn) {
      setShowModal(true);
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    if (!isSubmitting && !isLiked) {
      dispatch(incrementLike(id));
      setIsSubmitting(true);
      const model: IHeartItemModel = {
        userId,
        itemId: id,
      };
      CallApi<IUserDto>(getHeartItemUrl(id), 'POST', model)
        .then(({ data }) => {
          dispatch(updateUser(data));
        })
        .catch((error) => {
          dispatch(decrementLike(id));
          onExceptionTestForClosed(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <Button size="sm" className={classNames('btn-like')} onClick={onClick} disabled={isSubmitting || isLiked}>
      <div className={classNames('love', { liked: isLiked })}>
        <label htmlFor="love">
          <i className="material-icons">{isLiked || isSubmitting ? 'favorite' : 'favorite_border'}</i>
        </label>
      </div>
      {likeCount}
    </Button>
  );
};

export default LikeButton;
