import LikeButton from './LikeButton';
import { IGalleryItemDto } from 'types/Gallery';
import { iconMarker } from '../../images/icons';

interface IGalleryThumbnailItemBaseProps {
  item: IGalleryItemDto;
  likeButtonPlacement: 'onMedia' | 'below';
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const GalleryItem: React.FC<IGalleryThumbnailItemBaseProps> = ({
  item: { username, id, thumbnailUrl, originalUrl, likeCount, state },
  likeButtonPlacement,
  setShowModal,

}) => {
  const imageUrl = thumbnailUrl || originalUrl || state;
  return (
    <>
      <div className="gallery-card col-6 col-sm-6 col-md-4">
        <div className="gallery-item gallery-image gallery-page">
          {likeButtonPlacement === 'onMedia' && (
            <LikeButton id={id} likeCount={likeCount} setShowModal={setShowModal} />
          )}
          <div className="gallery-info">
          <span className="username">{username}</span>
          <span className="location-data">
          <img src={iconMarker} alt="location-icon" className="img-fluid icon-pin" />
          <span>{state}</span>
        </span>
        </div>
          <div className="background-img-group">
            <img src={imageUrl} alt="" className="img-fluid" />
            <img src={imageUrl} alt="" className="img-fluid backer" />
          </div>
        </div>
        <LikeButton id={id} likeCount={likeCount} setShowModal={setShowModal} />
      </div>
    </>
  );
};

export default GalleryItem;
