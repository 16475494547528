import { useAppSelector } from 'hooks/useReduxHelper';
import { selectIsSignedIn } from 'slices/User/UserSlice';
import { Navigate } from 'react-router-dom';
import { LandingRoute } from '../../constants/Routes';

const PrivateRoute: React.FC = ({ children }) => {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  return isSignedIn ? <>{children}</> : <Navigate to={LandingRoute} />;
};

export default PrivateRoute;
